.gallery-slider{
    .card-list{
        .items-slide{
            width: 350px;
            border-radius: 20px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            &.swiper-slide-active{
                .card-info{
                    .content {
                        bottom: 35%;
                        .card-link {
                            display: flex;
                        }
                    }
                    &::before {
                        display: none;
                    }
                    &::after {
                        bottom: 0px;
                    }
                }
            }
            .swiper-slide-shadow-left{
                background-image: none;
            }
            .swiper-slide-shadow-right{
                background-image: none;
            }
        }
    }
}
.custom-swiper-pagination {
    width: 100%;
    position: absolute;
    z-index: 99;
    bottom: -4rem;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    column-gap: 3px;
    outline: none;
    .swiper-pagination-bullet {
        position: relative;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        border: 2px solid $white;
        box-shadow: 0 0 0 2px $hitam;
        background-color: transparent;
        margin: 0 0.25rem;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        z-index: 100;
        outline: none;
        &.swiper-pagination-bullet-active {
            background-color: $red;
        }
    }
}