.search-results {
    margin-top: 60px;
    .search-container {
        position: relative;
        input {
            background: $white;
            border: 0.5px solid rgba(0, 0, 0, 0.3);
            box-sizing: border-box;
            border-radius: 10px;
            width: 100%;
            font-size: 13px;
            line-height: 19px;
            color: $hitam;
            padding: 20px 50px 20px 30px;
        }
        button.btn-submit {
            position: absolute;
            right: 0px;
            top: 0px;
            bottom: 0px;
            outline: none;
            padding: 0rem 1rem;
            border-radius: 0px 10px 10px 0px;
            -webkit-border-radius: 0px 10px 10px 0px;
            -moz-border-radius: 0px 10px 10px 0px;
            -ms-border-radius: 0px 10px 10px 0px;
            -o-border-radius: 0px 10px 10px 0px;
        }
    }
    .results-total {
        font-size: 18px;
        line-height: 44px;
        color: $hitam;
        text-align: left;
        @include media-breakpoint-down(sm){
            font-size: 12px;
        }
    }
    .link.load-more {
        display: table;
        margin: auto;
        padding: 30px 0px;
        outline: none;
        border: none;
        background-color: transparent;
    }
}