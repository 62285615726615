.section-form {
    position: relative;
    margin-bottom: 1.5rem;
    &.was-validated {
        .form-control {
            &:invalid,
            &.is-invalid {
                border-color: #FF0000;
                padding-right: calc(1.5em + 1.5rem);
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF0000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF0000' stroke='none'/%3e%3c/svg%3e");
                background-repeat: no-repeat;
                background-position: right calc(.375em + .75rem) center;
                background-size: calc(.75em + .375rem) calc(.75em + .375rem);
                box-shadow: inset 0 0 0 1px #FF0000, 0 0 0 #0000;
            }
        }
    }
    select.form-control {
        background-image: url("../images/icons/select-arrow.svg");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 20px);
        background-position-y: 50%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        option:first-child{
            display: none;
        }
    }
    .form-control {
        border-radius: 0;
        border: none;
        background-color: $white;
        padding: 1.25rem 1rem .375rem 1rem;
        position: relative;
        z-index: 2;
        font-size: 1.125rem;
        display: block;
        width: 100%;
        font-weight: 400;
        line-height: 1.5;
        color: #333333;
        transition: all .4s ease;
        box-shadow: inset 0 0 0 1px #70707033, 0 0 0 #0000;
        &:not(textarea) {
            height: calc(1.5rem + 1rem + 2px);
        }
        &:focus {
            &+label {
                font-size: .625rem;
                top: 16px;
                color: #33333380;
            }
        }
        &:not(textarea) {
            height: 70px;
        }
        &.has-value {
            &+label {
                font-size: .625rem;
                top: 16px;
                color: #33333380;
            }
        }
        &+label {
            position: absolute;
            padding: 0;
            z-index: 2;
            top: 24.5px;
            margin: 0;
            pointer-events: none;
            font-size: .875rem;
            left: 1rem;
            font-weight: 700;
            right: auto;
            color: #333333;
            text-transform: uppercase;
            letter-spacing: 2px;
            transition: all .2s ease;
            .required {
                color: $red;
            }
        }
        input,
        select {
            height: 60px;
            border: solid 1px rgba(112, 112, 112, 0.2);
            border-radius: 0;
            &:focus {
                border: 1px solid $red;
                box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1) !important;
                box-shadow: none;
                outline: 0 none;
            }
            @include media-breakpoint-down(sm) {
                height: 55px;
            }
            @include media-breakpoint-only(md) {
                height: 55px;
            }
        }
        .select-form {
            &::before {
                content: "\f0d7";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                color: $hitam;
                position: absolute;
                background: white;
                padding-right: 0.5rem;
                font-size: 23px;
                z-index: 9;
                right: 3%;
                top: 52%;
                @include media-breakpoint-down(sm) {
                    right: 1%;
                    font-size: 19px;
                    top: 54%;
                }
                @include media-breakpoint-only(md) {
                    right: 1%;
                    font-size: 19px;
                    top: 54%;
                }
            }
        }
    }
    textarea.form-control {
        padding: 2rem 1rem .375rem 1rem;
    }
    .select2.select2-container .selection .select2-selection--single {
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        height: 70px;
        padding: 1.25rem 1rem 0.375rem 1rem;
        border: none;
        padding-left: 8px;
        background-color: #fff;
        position: relative;
        z-index: 2;
        font-size: 1.125rem;
        display: block;
        width: 100%;
        font-weight: 400;
        line-height: 1.5;
        color: #333333;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        -webkit-box-shadow: inset 0 0 0 1px #70707033, 0 0 0 #0000;
        box-shadow: inset 0 0 0 1px #70707033, 0 0 0 #0000;
        .select2-selection__placeholder {
            font-size: .875rem;
            font-weight: 600;
            color: #333333;
            text-transform: uppercase;
            letter-spacing: 2px;
            min-height: 40px;
        }
        .select2-selection__rendered {
            height: 100%;
            display: flex;
            align-items: center;
        }
        .select2-selection__arrow {
            height: auto;
            top: 0px;
            bottom: 0px;
            right: 10px;
        }
    }
    &.select label {
        position: absolute;
        padding: 0;
        z-index: 2;
        top: 24.5px;
        margin: 0;
        pointer-events: none;
        font-size: .875rem;
        left: 1rem;
        font-weight: 700;
        right: auto;
        color: #333333;
        text-transform: uppercase;
        letter-spacing: 2px;
        transition: all .2s ease;
        .required {
            color: $red;
        }
        @include media-breakpoint-up(xl){
            visibility: hidden;
            opacity: 0;
        }
    }
    &.select .has-value {
        &+.select2-container {
            &+.select-label{
                font-size: .625rem;
                top: 16px;
                color: #33333380;
                visibility: visible;
                opacity: 1;
            }
        }
    }
}