.home-benefit {

    h2.title {
        order: 1;
        @include media-breakpoint-up(lg) {
            max-width: 28ch;
        }
        @include media-breakpoint-down(md) {
            text-align: left;
        }
        
        span {
            background-color: $red;
            padding: 5px 15px;
            border-radius: 10px;
            color: $white;
        }
    }
    h3.sub-title {
        @include media-breakpoint-down(md) {
            text-align: left;
            margin-bottom: .25rem;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    &__item {
        width: 100%;
        padding: 15px;
        background-color: $white;
        border-radius: 10px;
        @include media-breakpoint-up(md) {
            width: calc(50% - 10px);
            padding: 20px;
        }
        @include media-breakpoint-up(lg) {
            width: calc(33.333333% - 13.5px);
            padding: 30px;
        }
    }

    &__icon {
        margin-bottom: 30px;
        width: 40px;
        height: 40px;
        object-fit: contain;
    }

    &__title {
        font-weight: 500;
        
    }
}