.applications {
    position: relative;
    &::before {
        position: absolute;
        content: '';
        background: $soft-blue;
        height: 720px;
        width: 100%;
        z-index: -1;
        top: 0px;
        left: 0px;
        @include media-breakpoint-only(sm) {
            height: 500px;
        }
        @include media-breakpoint-down(xs) {
            height: 600px;
        }
    }
    .text-container {
        text-align: center;
        margin-bottom: 40px;
        @include media-breakpoint-down(sm) {
            padding: 0px;
        }
    }
}