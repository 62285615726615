.setpass {

    #resetpassform {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        margin: auto;
        row-gap: 25px;
        position: relative;
        margin-top: -50px;

        .field-group {
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            width: 100%;

            label {
                font-size: 16px;
                color: #5E5E5E;
            }

            .switch-form {
                text-align: right;
                color: #5E5E5E;
            }

            .field-input {
                position: relative;

                input {
                    width: 100%;
                    background-color: #F4F4F4;
                    border-radius: 5px;
                    border: none;
                    padding: 15px;
                    outline: none;
                    border: 1px solid transparent;
                    transition: border .3s ease;

                    &:focus {
                        border-color: $red;
                    }
                }

                .toggle-password {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    cursor: pointer;
                }

                .submit-btn {
                    background-color: $red;
                    color: $white;
                    border-radius: 50px;
                    border: 1px solid transparent;
                    padding: 15px;
                    width: 100%;
                    transition: all .3s ease;

                    &:hover {
                        background-color: $white;
                        border-color: $red;
                        color: $red;
                    }
                }
            }

        }
    }
}