.related-news.news-articles {
    .section-title {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 44px;
        color: $hitam;
        margin-bottom: 40px;
        padding: 60px 12px 0px 12px;
        text-align: left;
        @include media-breakpoint-down(md){
            font-size: 22px;
            margin-bottom: 30px;
        }
    }
    .image-container {
        padding-right: 0px;
    }
    .row {
        margin-bottom: 30px;
        .section-text {
            padding-left: 30px;
            width: 95%;
            @include media-breakpoint-down(sm){
                padding-left: 0px;
                width: 100%;
            }
            .title {
                font-weight: 300;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                @include media-breakpoint-down(sm){
                    -webkit-line-clamp: 2;
                }
            }
        }
    }
    .link {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        i {
            margin-left: 15px;
        }
    }
}