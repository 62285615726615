section.home-slider {
    overflow: hidden;
    position: relative;
    .hero {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: .5);
        }

        &__content {
            text-align: center;
            position: relative;
            z-index: 1;
            @include media-breakpoint-down(md) {
                padding-left: 30px;
                padding-right: 30px;
            }

            &-title {
                font-size: 22px;
                font-weight: 700;
                color: $white;
                @include media-breakpoint-up(md){
                    font-size: 32px;
                }
                @include media-breakpoint-up(lg){
                    font-size: 40px;
                }
            }
            &-desc {
                font-size: 16px;
                color: $white;
                @include media-breakpoint-down(lg){
                    font-size: 13px;
                }
            }
        }
    }
    .home-slider-container {
        .items {
            .background-overly{
                background-color: rgba(0, 0, 0, 0.3);
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: 0;
                width: 100%;
                height: 100%;
            }
            .hero-img.adaptive-backgrounds {
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: absolute;
                object-fit: cover;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                @include media-breakpoint-down(lg){
                    background-size: contain;
                }
                .slider-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 50%;
                    height: 100%;
                    margin: auto;
                    @include media-breakpoint-only(md){
                        width: 70%;
                    }
                    @include media-breakpoint-only(sm){
                        width: 80%;
                    }
                    @include media-breakpoint-down(xs){
                        width: 90%;
                    }
                    .slider-title {
                        color: inherit;
                        font-weight: 700;
                        text-align: center;
                        font-size: 2.4rem;
                        z-index: 10;
                        @include media-breakpoint-only(md){
                            font-weight: bold;
                            font-size: 30px;
                            line-height: 32px;
                        }
                        @include media-breakpoint-down(sm){
                            font-weight: bold;
                            font-size: 22px;
                            line-height: 28px;
                        }
                        @include media-breakpoint-down(xs){
                            text-align: left;
                        }
                    }
                    .sub-title {
                        color: inherit;
                        text-align: center;
                        margin: 0px 60px;
                        z-index: 10;
                        color: $white;
                        @include media-breakpoint-down(xs){
                            text-align: left;
                            margin: 0px;
                        }
                    }
                }
            }
        }
    }
    .home-slider-controls{
        @include media-breakpoint-down(xs){
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 1.5rem;
            left: 0;
            right: 0;
        }
        .custom-swiper-pagination{
            bottom: 3rem;
            outline: none;
            @include media-breakpoint-down(md) {
                display: flex;
                opacity: 1;
            }
            .swiper-pagination-bullet{
                border: 2px solid #000;
                box-shadow: 0 0 0 2px #fff;
            }
            @include media-breakpoint-down(xs){
                position: static;
            }
        }
        .home-slider-next {
            position: absolute;
            display: block;
            bottom: 45%;
            color: #fff;
            right: 3%;
            z-index: 99;
            padding: 1rem;
            cursor: pointer;
            outline: none;
            @include media-breakpoint-down(xs){
                position: static;
                svg{
                    height: 20px;
                }
            }
        }
        .home-slider-prev {
            position: absolute;
            display: block;
            bottom: 45%;
            color: #fff;
            left: 3%;
            z-index: 99;
            padding: 1rem;
            cursor: pointer;
            outline: none;
            @include media-breakpoint-down(xs){
                position: static;
                svg{
                    height: 20px;
                }
            }
        }
    }
}