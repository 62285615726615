.company-values {
    background-color: #FFFFFF;
    overflow: hidden;
    .section-title{
        padding-bottom: 60px;
        @include media-breakpoint-down(md) {
            padding-bottom: 30px;

            .sub-title {
                margin-bottom: 10px;
            }
        }
    }
    .vision-missions {
        padding-bottom: 60px;
        .row{
            row-gap: 40px;
        }
        .vision-container,
        .missions-container {
            position: relative;
            width: 85%;
            margin-left: auto;
            @include media-breakpoint-down(xs){
                width: 90%;
            }
            .icon {
                height: 175px;
                width: auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                position: absolute;
                left: -20%;
                @include media-breakpoint-down(md){
                    height: 100px;
                    left: -10%;
                }
            }
            .title {
                font-weight: 600;
                font-size: 30px;
                line-height: 44px;
                color: #4e5256;
                position: relative;
                margin-bottom: 20px;
                &::after {
                    content: "";
                    position: absolute;
                    bottom: -10px;
                    left: 0px;
                    width: 10%;
                    border-bottom: 6px solid #ED0E15;
                }
                @include media-breakpoint-down(md){
                    font-size: 22px;
                }
            }
            .text {
                width: 80%;
                margin-right: auto;
                font-size: 16px;
                line-height: 26px;
                color: $hitam;
            }
            .list {
                li:not(.list-items) {
                    display: none;
                }
            }
        }
    }
    .list-values {
        background-color: #E5E5E5;
        padding: 90px 0px;
        .row {
            row-gap: 60px;
            .items {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                width: 80%;
                margin: auto;
                height: 100%;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
                .icons {
                    margin-bottom: 20px;
                    max-width: 85px;
                    max-height: 85px;
                }
                .title {
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 44px;
                    color: #4e5256;
                    @include media-breakpoint-down(sm){
                        font-size: 18px;
                    }
                }
                .desc {
                    font-size: 16px;
                    line-height: 26px;
                    color: $hitam;
                    @include media-breakpoint-down(sm) {
                        text-align: center;
                    }
                }
            }
        }
    }
}