body {
    font-family: $font-1;
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-1;
    margin-bottom: 1rem;
    line-height: 1.22;
    color: $hitam;
    font-weight: 400;
}

h1 {
    font-size: 2rem;
    @include media-breakpoint-down(lg) {
        font-size: 2.3rem;
    }
    @include media-breakpoint-down(md) {
        font-size: 1.75rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: 1.875rem;
    }
}

h2 {
    font-size: 1.9rem;
    @include media-breakpoint-down(lg) {
        font-size: 2.375rem;
    }
    @include media-breakpoint-down(md) {
        font-size: 1.875rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: 1.875rem;
    }
}

h3 {
    font-size: 1.875rem;
    @include media-breakpoint-down(lg) {
        font-size: 1.875rem;
    }
    @include media-breakpoint-down(md) {
        font-size: 1.5625rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: 1.25rem;
    }
}

h4 {
    font-size: 1.25rem;
    @include media-breakpoint-down(lg) {
        font-size: 1.5rem;
    }
    @include media-breakpoint-down(md) {
        font-size: 1.375rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: 1.25rem;
    }
}

h5 {
    font-size: 1.375rem;
    @include media-breakpoint-down(lg) {
        font-size: 1.25rem;
    }
    @include media-breakpoint-down(md) {
        font-size: 1.125rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: 1.125rem;
    }
}

h6 {
    font-size: 1.125rem;
    @include media-breakpoint-down(lg) {
        font-size: 1.125rem;
    }
    @include media-breakpoint-down(md) {
        font-size: 1rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: 1rem;
    }
}

p {
    font-family: $font-1;
    color: $text-main;
    font-size: 16px;
    line-height: 26px;
    @include media-breakpoint-down(md) {
        font-size: 13px;
        line-height: 23px;
    }
}

a {
    text-decoration: none;
}

b {
    font-weight: $font-weight-bolder;
}

strong {
    font-weight: $font-weight-bolder;
}

ul,
ol {
    padding-left: 22px;
    li {
        line-height: 2;
        text-align: left;
        @include media-breakpoint-down(sm) {
            font-size: 1rem;
        }
    }
}

.heading {
    margin-bottom: 2rem;
    &.heading--2 {
        display: flex;
        align-items: center;
        .divider {
            flex: auto;
            height: 1px;
            border-top: 1px solid #707070;
            margin: 0 1.5rem;
            opacity: .2;
        }
        .subheading {
            font-size: 1.125rem;
            color: #3339;
            display: block;
            a {
                color: #3339;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0;
    }
    em {
        font-size: .8125rem;
        display: block;
        color: rgba(51, 51, 51, .6);
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: $font-weight-bolder;
        font-style: normal;
        margin-bottom: .75rem;
    }
    .fs-6 {
        font-style: italic;
    }
}

footer {
    h3 {
        font-family: $font-1;
        font-weight: 900;
        line-height: 2.33;
        letter-spacing: 2.4px;
        color: white;
        text-transform: uppercase;
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
        @include media-breakpoint-only(md) {
            text-align: center;
        }
    }
    ul {
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
        @include media-breakpoint-only(md) {
            text-align: center;
        }
        li {
            a {
                color: white;
                font-family: $font-1;
                font-size: 16px;
                line-height: 1.63;
                font-weight: normal;
                transition: all .3s ease;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -ms-transition: all .3s ease;
                -o-transition: all .3s ease;
                &:hover {
                    text-decoration: none;
                    color: rgba(255, 255, 255, 0.7)
                }
            }
        }
    }
    .our-office {
        @include media-breakpoint-down(sm) {
            margin-bottom: 2rem;
        }
        @include media-breakpoint-only(md) {
            margin-bottom: 2rem;
        }
    }
    .contact-us {
        @include media-breakpoint-down(sm) {
            margin-bottom: 3rem;
        }
        @include media-breakpoint-only(md) {
            margin-bottom: 1rem;
        }
        a {
            @include media-breakpoint-down(sm) {
                display: block;
                width: 100%;
            }
            @include media-breakpoint-only(md) {
                display: block;
                width: 100%;
            }
        }
    }
    a {
        color: white;
        font-family: $font-1;
        font-size: 16px;
        line-height: 1.63;
        font-weight: normal;
        text-decoration: none;
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
        @include media-breakpoint-only(md) {
            text-align: center;
        }
        &:hover {
            text-decoration: none;
            color: rgba(255, 255, 255, 0.7)
        }
    }
    p {
        &.p__bottom {
            font-size: 12px;
            font-family: $font-1;
            color: rgba(255, 255, 255, 0.7);
            @include media-breakpoint-down(sm) {
                text-align: center;
                font-size: 11px;
            }
            @include media-breakpoint-only(md) {
                text-align: center;
                font-size: 11px;
            }
        }
        a {
            font-size: 12px;
            font-family: $font-1;
            color: rgba(255, 255, 255, 0.7);
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
            @include media-breakpoint-only(md) {
                text-align: center;
            }
        }
    }
}


/*Vinilion Edit*/

a.link, button.link {
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    color: $link-color;
    font-size: 14px;
    line-height: normal;
    font-weight: 700;
    letter-spacing: 0.2em;
    margin-top: .8rem;
    i {
        font-size: 12px;
        margin-left: 3px;
        transition: all .3s linear;
        -webkit-transition: all .3s linear;
        -moz-transition: all .3s linear;
        -ms-transition: all .3s linear;
        -o-transition: all .3s linear;
    }
    &:hover {
        i {
            transform: translatex(5px);
            -webkit-transform: translatex(5px);
            -moz-transform: translatex(5px);
            -ms-transform: translatex(5px);
            -o-transform: translatex(5px);
        }
    }
    @include media-breakpoint-down(md) {
        text-align: center;
        display: block;
        max-width: max-content;
        margin: 1.8rem auto;
    }
}

h3.sub-title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.2em;
    z-index: 10;
    &.dark {
        color: $text-grey;
    }
    &.light {
        color: rgba(255, 255, 255, 0.6);
    }
    @include media-breakpoint-down(sm) {
        font-size: 11px;
        text-align: center;

    }
    @include media-breakpoint-only(md) {
        text-align: center;
    }
}

h2.title {
    font-weight: bold;
    font-size: 35px;
    line-height: 45px;
    z-index: 10;
    margin-bottom: 40px;
    &.dark {
        color: $text-main;
    }
    &.light {
        color: $white;
    }
    &.margin-title{
        margin: 80px 0px 30px 0px;
        @include media-breakpoint-down(sm){
            margin: 40px 0px 10px 0px;
        }
    }
    @include media-breakpoint-down(sm) {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 10px;
    }
    @include media-breakpoint-only(md) {
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        text-align: center;
        margin-bottom: 20px;
    }
}

p.descriptions {
    font-family: $font-1;
    font-size: 16px;
    line-height: 26px;
    &.dark {
        color: $text-main;
    }
    &.light {
        color: $white;
    }
    @include media-breakpoint-down(md) {
        font-size: 13px;
        line-height: 23px;
        text-align: center;
    }
}