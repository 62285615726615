$biru: #4e5256;
$biru-2: #0057ad;
$text-main: #25293A;
$hitam: #000000;
$red: #ED0E15;
$link-color: #ED0E15;
$blue: #1B1D2E;
$soft-blue: #4e5256;
$dark-blue: #1B1D2E;
$orange: #FF6400;
$white: #FFFFFF;
$gray: #383737;
$soft-gray: #5F5F5F;
$text-black: #333333;
$text-grey: #707070;