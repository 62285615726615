.anc-container {
    display: flex;
    // align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    height: 100%;
    // min-height: 100vh;
    @include media-breakpoint-down(lg){
        flex-direction: column;
        min-height: auto;
    }
    &.sticky{
        .section-title{
        position: fixed;
        top: 0;
        height: 100%;
        overflow: hidden;
        }
        .section-scroll {
            height: 100%;
        }
        &.awards-container{
            .section-title{
                left: 0;
            }
        }
        &.certifications-container{
            .section-title{
                right: 0;
            }
        }
    }
    .section-title {
        background-color: $soft-blue;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 80px 80px 80px 100px;
        @include media-breakpoint-up(xl){
            width: 610px;
            // height: 100vh;
        }
        @include media-breakpoint-only(lg){
            padding: 30px 40px 30px 60px;
        }
        @include media-breakpoint-only(md){
            padding: 30px;
        }
        @include media-breakpoint-down(sm){
            padding: 30px 15px 30px 15px;
            height: auto;
            width: 100%;
        }
        .anc-title {
            font-weight: bold;
            font-size: 35px;
            line-height: 45px;
            color: $white;
            @include media-breakpoint-down(sm){
                font-size: 18px;
            }
        }
        .anc-desc {
            color: $white;
            text-align: left;
        }
    }
    .section-scroll {
        // height: 100vh;
        overflow-y: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        position: relative;
        padding: 30px 0;
        display: flex;
        align-items: center;
        &::-webkit-scrollbar {
            display: none;
        }
        @include media-breakpoint-up(xl){
            width: calc(100vw - 610px);
            position: relative;
            background-color: #FFF;
            &.certifications{
                margin-right: auto;
            }
            &.awards{
                margin-left: auto;
            }
        }
        @include media-breakpoint-down(lg){
            height: auto;
            overflow: hidden;
            width: 100%;
            &::after{
                content: '';
                background-color: $soft-blue;
                position: absolute;
                height: 170px;
                width: 100%;
                z-index: 0;
                top: 0;
                left: 0;
            }
        }
        .vertical-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin: auto;
            row-gap: 40px;
            column-gap: 60px;
            overflow: hidden;
            padding: 30px 0px;
            width: 90%;
            max-width: 750px;
            @include media-breakpoint-down(lg){
                column-gap: 40px;
            }
            .items {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .logo {
                    height: 175px;
                    width: 175px;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    margin-bottom: 15px;
                    @include media-breakpoint-only(md){
                        height: 150px;
                        width: 150px;
                    }
                }
                p {
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                }
            }
            @include media-breakpoint-down(lg){
                display: none;
            }
        }
        .horizontal-slide{
            max-width: 100vw;
            padding-bottom: 120px;
            position: relative;
            width: 100%;
            @include media-breakpoint-down(md) {
                padding-bottom: 30px;
            }
            .tns-nav{
                bottom: 4rem;
            }
            @include media-breakpoint-down(lg){
                .controls-slider-prev{
                    left: 5%;
                    bottom: 3.5rem;
                }
                .controls-slider-next{
                    right: 5%;
                    bottom: 3.5rem;
                }
            }
            .slider-items{
                padding: 5px 0px;
                position: relative;
                z-index: 10;
                .items{
                    .card-items{
                        min-height: 250px;
                        background-color: #FFF;
                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                        border-radius: 20px;
                        -webkit-border-radius: 20px;
                        -moz-border-radius: 20px;
                        -ms-border-radius: 20px;
                        -o-border-radius: 20px;
                        padding: 20px;
                        margin: auto;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-direction: column;
                        .logo{
                            height: 175px;
                            width: 175px;
                            background-position: center;
                            background-size: 100%;
                            background-repeat: no-repeat;
                            margin: auto;
                            margin-bottom: 15px;
                            flex: 1;
                            @include media-breakpoint-down(sm){
                                width: 130px;
                                height: 130px;
                            }
                        }
                        p{
                            text-align: center;
                        }
                        @include media-breakpoint-only(lg){
                            min-height: 300px;
                        }
                    }
                }
            }
        }
    }
}
.horizontal.onSticky{
    .vertical-slide{
        overflow: visible;
    }
}