.products {
    $p: &;
    position: relative;
    &::before {
        position: absolute;
        content: '';
        background: $soft-blue;
        background-image: var(--bg);
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        z-index: -1;
        top: 0px;
        left: 0px;
        @include media-breakpoint-down(sm) {
            height: 100%;
        }
    }
    &::after {
        position: absolute;
        content: '';
        background: $soft-blue;
        opacity: .5;
        height: 100%;
        width: 100%;
        z-index: 0;
        top: 0px;
        left: 0px;
        @include media-breakpoint-down(sm) {
            height: 100%;
        }
    }

    .text-container {
        text-align: center;
        margin-bottom: 40px;
        position: relative;
        z-index: 1;
        @include media-breakpoint-down(md) {
            text-align: left;
            width: 100%;
            margin: auto;

            h1.title.light {
                margin-bottom: 0;
            }

            p.descriptions {
                text-align: left;
                width: 100%;
                margin-top: 0 !important;
            }
        }
        @include media-breakpoint-down(sm) {
            padding: 0px;
            width: 80%;
        }
    }

    &-list {
        list-style: none;
        padding: 0;
        margin: 30px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        @include media-breakpoint-down(md) {
            padding: 0 10px;
        }
    }

    &-item {
        width: calc(100% / 3 - 1rem);
        &.not-found {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 32px;
            @include media-breakpoint-down(md) {
                font-size: 24px;
            }
        }
        @include media-breakpoint-down(lg) {
            width: calc(100% / 2 - .75rem);
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    &-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: .5px solid #A1A1A1;
        padding: 70px 25px;
        height: 100%;
        justify-content: space-between;
        position: relative;
        background-color: $white;
        &:hover {

            #{$p}-card__head-title {
                color: $red;
            }

            #{$p}-card__body-cta {
                transform: translate(-50%, -50%);
                width: max-content;
                opacity: 1;
                visibility: visible;   
            }
        }

        &__head {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            row-gap: .5rem;

            &-subterm {
                font-size: 12px;
                font-weight: 500;
                color: #C1C1C1;
                text-transform: uppercase;
            }

            &-term {
                position: absolute;
                top: 20px;
                left: 0;
                background-color: $biru;
                border-radius: 0 50px 50px 0;
                font-size: 12px;
                color: $white;
                padding: 5px 15px;
            }

            &-title {
                font-size: 20px;
                font-weight: 700;
                order: 1;
            }
        }

        &__body {
            width: 100%;

            &-cover {
                width: 100%;
                height: 250px;
                object-fit: contain;
            }
            &-cta {
                background-color: $red;
                color: $white;
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                gap: 5px;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translate(-50%, calc(-50% + 1.5rem));
                width: max-content;
                opacity: 0;
                visibility: hidden;
                transition: opacity .3s ease, transform .3s ease, visibility .3s ease;
                @include media-breakpoint-down(md) {
                    opacity: 1;
                    visibility: visible;
                    transform: translate(-50%, -50%);
                    width: max-content;
                }
            }
        }
    }

    &-filter {

        .wpgb-facet {
            fieldset {
                margin-top: 25px;

                .wpgb-checkbox[aria-pressed="true"] {

                    .wpgb-checkbox-control {
                        background-color: $red;
                        border-color: $red;
                    }
                }
                
            }
        }

        .wpgb-facet-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 !important;
            padding: 15px 28px 15px 28px !important;
            color: $black;
            text-transform: uppercase;
            font-weight: 500;
            border-radius: 50px;
            transition: background-color .3s ease;
            cursor: pointer;
            &.expanded {
                background-color: $red;
                color: $white;
                svg {
                    transform: scaleY(-1);

                    path {
                        fill: $white;
                    }
                }
            }

            svg {
                cursor: pointer;
                position: relative;
                z-index: 4;
                pointer-events: visible;
                transition: transform .3s ease;

                path {
                    fill: $black;
                }
            }

            @include media-breakpoint-down(sm) {
                font-size: 14px !important;
            }
        }

        &.wrapper {
            position: sticky;
            top: 100px;
            @include media-breakpoint-down(md) {
                position: relative;
                top: 0;
            }
        }

        &.search {
            fieldset {
                @include media-breakpoint-down(md) {
                    margin-bottom: 20px !important;
                    margin-top: 0;
                }
            }
            .wpgb-input {
                padding: 15px 82px 15px 28px !important;
                height: auto !important;
                border: 1px solid #A1A1A1 !important;
                border-radius: 100px !important;
            }
            .wpgb-clear-button {
                right: 40px !important;
            }
            .wpgb-input-icon {
                left: auto;
                right: 0;
                margin: 0 28px 0 28px;
            }
        }

        &.type {
            margin-bottom: 40px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }

            .wpgb-facet {
                border: 1px solid #A1A1A1;
                border-radius: 30px;
                overflow: hidden;
            }

            fieldset {
                padding: 5px 28px 0 28px !important;
                max-height: 300px;
                overflow: auto;
                display: none;
                .wpgb-radio-control {
                    display: none;
                }

                li {
                    &.level-0 {
                        &:not(:first-child) {
                            margin-top: 30px !important;
                        }
                        .wpgb-radio {
                            pointer-events: none;
                        }
                        .wpgb-radio-label {
                            font-size: 18px;
                            color: #5E5E5E;
                            font-weight: 400;
                        }
                    }
                    &.level-1 {
                        .wpgb-radio {
                            padding-left: 20px;
                        }
                        .wpgb-radio-label {
                            font-size: 16px;
                            color: #5E5E5E;
                            font-weight: 400;
                        }
                    }
                    &.level-2 {
                        .wpgb-radio {
                            padding-left: 40px;
                        }
                        .wpgb-radio-label {
                            font-size: 14px;
                            color: #5E5E5E;
                            font-weight: 400;
                        }
                    }

                    > .wpgb-radio[aria-pressed="true"] .wpgb-radio-label {
                        color: $red;
                    }
                }
            }

            .wpgb-checkbox-facet {

                > ul {
    
                    > li {
                        > .wpgb-checkbox {
                            pointer-events: none;
                        }
                        .wpgb-checkbox-control {
                            display: none;
                        }
                        .wpgb-checkbox-label {
                            font-size: 18px;
                            color: #5E5E5E;
                            font-weight: 400;
                        }
                        &:not(:first-child) {
                            margin-top: 30px !important;
                        }
        
                        ul {
                            margin-top: 15px;
                            li {
                                margin-top: 15px;
                                > .wpgb-checkbox[aria-pressed="true"] .wpgb-checkbox-label {
                                    color: $red;
                                }
                                .wpgb-checkbox-label {
                                    font-size: 14px;
                                    color: #5E5E5E;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.material {
            margin-bottom: 40px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }

            .wpgb-facet {
                border: 1px solid #A1A1A1;
                border-radius: 30px;
                overflow: hidden;
            }

            fieldset {
                padding: 5px 28px 0 28px !important;
                max-height: 300px;
                overflow: auto;
                display: none;
            }

            li {
                .wpgb-checkbox-label {
                    font-size: 18px;
                    color: #5E5E5E;
                    font-weight: 400;
                }
                &:not(:first-child) {
                    margin-top: 30px !important;
                }

                ul {
                    margin-top: 25px;
                    li {
                        margin-top: 20px;
                        .wpgb-checkbox-label {
                            font-size: 16px;
                            color: #5E5E5E;
                            font-weight: 400;
                        }       
                    }
                }
            }
        }

        &.segmentation {
            margin-bottom: 40px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }

            .wpgb-facet {
                border: 1px solid #A1A1A1;
                border-radius: 30px;
                overflow: hidden;
            }

            fieldset {
                padding: 5px 28px 0 28px !important;
                max-height: 300px;
                overflow: auto;
                display: none;
            }

            li {
                .wpgb-checkbox-label {
                    font-size: 18px;
                    color: #5E5E5E;
                    font-weight: 400;
                }
                &:not(:first-child) {
                    margin-top: 30px !important;
                }

                ul {
                    margin-top: 25px;
                    li {
                        margin-top: 20px;
                        .wpgb-checkbox-label {
                            font-size: 16px;
                            color: #5E5E5E;
                            font-weight: 400;
                        }       
                    }
                }
            }
        }

        &.connection {
            margin-bottom: 40px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }

            .wpgb-facet {
                border: 1px solid #A1A1A1;
                border-radius: 30px;
                overflow: hidden;
            }

            fieldset {
                padding: 5px 28px 0 28px !important;
                max-height: 300px;
                overflow: auto;
                display: none;
            }

            li {
                .wpgb-checkbox-label {
                    font-size: 18px;
                    color: #5E5E5E;
                    font-weight: 400;
                }
                &:not(:first-child) {
                    margin-top: 30px !important;
                }

                ul {
                    margin-top: 25px;
                    li {
                        margin-top: 20px;
                        .wpgb-checkbox-label {
                            font-size: 16px;
                            color: #5E5E5E;
                            font-weight: 400;
                        }       
                    }
                }
            }
        }

        &.pressure {
            margin-bottom: 40px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }

            .wpgb-facet {
                border: 1px solid #A1A1A1;
                border-radius: 30px;
                overflow: hidden;
            }

            fieldset {
                padding: 5px 28px 0 28px !important;
                max-height: 300px;
                overflow: auto;
                display: none;
            }

            li {
                .wpgb-checkbox-label {
                    font-size: 18px;
                    color: #5E5E5E;
                    font-weight: 400;
                }
                &:not(:first-child) {
                    margin-top: 30px !important;
                }

                ul {
                    margin-top: 25px;
                    li {
                        margin-top: 20px;
                        .wpgb-checkbox-label {
                            font-size: 16px;
                            color: #5E5E5E;
                            font-weight: 400;
                        }       
                    }
                }
            }
        }

        &.action {
            display: flex;
            align-items: center;
            gap: 15px;
            @include media-breakpoint-down(md) {
                margin-top: auto;
            }

            .wpgb-facet {
                flex: 1 1 auto;
            }

            .wpgb-button {
                padding: 12px 22px;
                width: 100%;
                border-radius: 100px;

                &.wpgb-reset {
                    background-color: $white;
                    color: $red;
                    border: 1px solid $red;
                }
                &.wpgb-apply {
                    background-color: $red;
                    color: $white;
                    border: 1px solid $white;
                }

                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                }
            }
        }

        &.option {

            .selection {

                fieldset {
                    @include media-breakpoint-down(md) {
                        margin-bottom: 0;
                    }
                }

                .wpgb-inline-list {
                    @include media-breakpoint-down(md) {
                        flex-wrap: nowrap;
                        overflow-x: auto;
                        white-space: nowrap;
                    }
                }

                .wpgb-button {
                    background-color: #D9D9D9 !important;
                    font-size: 12px !important;
                    color: #5E5E5E !important;
                    border: none !important;
                    border-radius: 50px;
                    padding: 5px 20px;
                    .wpgb-button-control {
                        order: 1;
                        &::before,
                        &::after {
                            background: #5E5E5E;
                        }
                    }
                }
            }

            .sort {
                @include media-breakpoint-down(md) {
                    display: none;
                }
                .wpgb-sort {
                    padding: 16px 15px;
                    height: auto;
                    border: 1px solid rgba($color: #373D584D, $alpha: .3);
                    border-radius: 5px;
                    text-transform: uppercase;
                }
                .wpgb-select-separator {
                    display: none;
                }
            }
        }

        &.load {
            display: flex;
            justify-content: center;
            margin-top: 50px;

            button {
                background-color: transparent;
                color: $red !important;
                font-size: 14px !important;
                text-transform: uppercase !important;
                letter-spacing: 2px !important;
            }
        }

        &.filter-offcanvas {
            display: none;
            position: fixed;
            flex-direction: column;
            top: 0;
            right: 0;
            overflow-y: auto;
            background-color: $white;
            width: 100%;
            height: 100%;
            z-index: 1000;
            transform: translateX(100%);
            transition: transform .3s ease-out;
            padding: 40px 20px;
            &.expanded {
                transform: translateX(0%);
            }
            @include media-breakpoint-down(md) {
                display: flex;
            }

            .label {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 25px;

                span {
                    font-size: 18px;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }

        &.filter-btn {
            display: none;
            background-color: $red;
            color: $white;
            padding: 15px 0;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 2px;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            gap: 5px;
            width: 100%;
            transition: opacity .3s ease, transform .3s ease, visibility .3s ease;
            @include media-breakpoint-down(md) {
                display: flex;
            }
        }
    }
}

.products-single {
    $s: &;

    .back-btn {
        margin: 70px 0 25px;
        background-color: transparent;
        padding: 0;
        border: none;
        color: $red;
        display: flex;
        align-items: center;
        gap: 1rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: left;
        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }

    #{$s}__content {
        order: 1;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-down(md) {
            margin-top: 25px;
        }

        &-subterm {
            order: -1;
            font-size: 14px;
            color: #C1C1C1;
            font-weight: 400;
            margin-bottom: 10px;
        }

        &-title {
            font-size: 35px;
            font-weight: 700;
        }
        
        &-brochure {
            display: flex;
            flex-wrap: wrap;
            @include media-breakpoint-down(md) {
                background-color: $biru;
                padding: 15px;
            }

            &-toggle {
                color: $white;
                display: none;
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                @include media-breakpoint-down(md) {
                    display: flex;
                }

                &.expanded {
                    svg {
                        transform: scaleY(-1);
                    }
                }

                svg {
                    transition: all .2s linear;
                }
            }

            &-label {
                text-transform: uppercase;
                font-weight: 400;
                margin-top: 15px;
                margin-bottom: 15px;
                font-size: 14px;
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &-list {
                display: grid;
                gap: 1rem;
                grid-template-columns: repeat(2, 1fr);
                align-items: center;
                width: 100%;
                margin: 0;
                padding: 0;
                list-style: none;
                @include media-breakpoint-down(md) {
                    display: none;
                    flex-direction: column;
                }
            }
        }

        &-item {
            display: flex;
            background-color: $white;
            @include media-breakpoint-down(md) {
                width: 100%;
                margin-top: 20px;
            }

            &:nth-child(1):nth-last-child(1) {
                grid-column: 1 / -1;
            }
        }

        &-link {
            color: $biru;
            font-weight: 700;
            display: flex;
            align-items: center;
            line-height: 1.3;
            width: 100%;
            justify-content: space-between;
            font-size: 14px;
            letter-spacing: 1px;
            padding: 10px 12px;
            text-transform: uppercase;
            gap: 15px;
            &:hover {
                color: $red;

                svg {
                    path {
                        stroke: $red;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                justify-content: space-between;
                width: 100%;
            }

            &.bim {
                background-color: $biru;
                padding: 22px 30px;
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: 3px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $white;
                &:hover {
                    color: $red;
                }
            }
        }

        &-link-svg {
            background-color: $biru;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            width: 4rem;
            aspect-ratio: 1/1;
            @include media-breakpoint-down(md) {
                background-color: $white;
            }
            
            svg {
                width: 20px;
                height: 20px;
                flex-shrink: 0;

                path {
                    @include media-breakpoint-down(md) {
                        stroke: $biru;
                    }
                }
            }
        }

        &-bim {
            &--popup {

                &.thankyou,
                &.login,
                &.brochure {
                    display: none;
                }
            }
        }
    }

    #{$s}__media {

        &-main {
            border: 1px solid #A1A1A1;
            overflow: hidden;
            position: relative;
        }

        &-term {
            position: absolute;
            top: 30px;
            left: 0;
            background-color: $biru;
            padding: 10px 35px;
            border-radius: 0 100px 100px 0;
            z-index: 1;
            color: $white;
        }

        &-thumbnail {
            margin-top: 25px;
            overflow: hidden;

            #{$s}__media-item {
                border: 1px solid #A1A1A1;
                cursor: pointer;

                &.swiper-slide-thumb-active {
                    border-color: $red;
                }
            }
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.products-specification {
    $s: &;
    padding-top: 0 !important;

    #{$s}__title {
        font-size: 20px;
    }

    hr {
        margin-bottom: 0;
    }

    #{$s}__table {
        width: 100%;
        border-collapse: collapse;

        tr {
            &:nth-child(even),
            &:hover {
                background-color: #ECECEC;
            }
        }

        th, td {
            padding: 20px;
            text-align: left;
            border-bottom: 1px solid #ddd;
            @include media-breakpoint-down(md) {
                display: block;
            }
        }

        th {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        td {
            font-weight: 400;
            color: #9C9C9C;
            @include media-breakpoint-down(md) {
                &::before {
                    content: attr(data-label);
                    color: $hitam;
                    margin-bottom: 5px;
                    font-weight: bold;
                    display: block;
                }
            }
        }
    }

}

.products-single {
    &__content {

        &-bim {
            &--popup {
                $p: &;
                &.thankyou {
                    display: flex;
                    flex-direction: column;
                    background-color: $white;
                    border-radius: 20px;
                    overflow: hidden;
                    position: relative;
                    
                    #{$p}-logo {
                        position: absolute;
                        top: 35px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    #{$p}-content {
                        align-items: center;
                        @include media-breakpoint-down(md) {
                            text-align: center;
                        }
                    }
                    #{$p}-title {
                        font-size: 50px;
                        font-weight: 700;
                        @include media-breakpoint-down(md) {
                            margin-top: 65px;
                        }
                        @include media-breakpoint-down(sm) {
                            font-size: 24px;
                        }
                    }
                    #{$p}-desc {
                        font-size: 20px;
                        @include media-breakpoint-down(sm) {
                            font-size: 14px;
                        }
                    }
                    #{$p}-cover {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        @include media-breakpoint-down(md) {
                            max-height: 350px;
                        }
                        @include media-breakpoint-down(sm) {
                            max-height: 250px;
                        }
                    }
                }

                &.login {
                    display: flex;
                    flex-direction: column;
                    background-color: $white;
                    border-radius: 20px;
                    overflow: hidden;
                    height: 100%;
                    #{$p}-content {
                        height: inherit;
                    }
                    #{$p}-text {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-center;
                        justify-content: center;
                        padding: 60px;
                        @include media-breakpoint-down(sm) {
                            padding: 40px;
                        }
                    }
                    #{$p}-logo {
                        order: -1;
                        margin: 0 auto 40px;
                    }
                    #{$p}-title {
                        font-size: 35px;
                        font-weight: 700;
                        @include media-breakpoint-down(sm) {
                            font-size: 24px;
                        }
                    }
                    #{$p}-desc {
                        font-size: 16px;
                        color: #5E5E5E;
                    }
                    #{$p}-cover {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        @include media-breakpoint-down(md) {
                            max-height: 250px;
                        }
                        @include media-breakpoint-down(sm) {
                            max-height: 150px;
                        }
                    }
                    #{$p}-form {
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        row-gap: 25px;
                        position: relative;

                        .field-group {
                            display: flex;
                            flex-direction: column;
                            row-gap: 5px;
                            width: 100%;

                            label {
                                font-size: 16px;
                                color: #5E5E5E;
                            }

                            .switch-form {
                                text-align: right;
                                color: #5E5E5E;
                            }

                            .field-input {
                                position: relative;

                                input {
                                    width: 100%;
                                    background-color: #F4F4F4;
                                    border-radius: 5px;
                                    border: none;
                                    padding: 15px;
                                    outline: none;
                                    border: 1px solid transparent;
                                    transition: border .3s ease;

                                    &:focus {
                                        border-color: $red;
                                    }
                                }
    
                                .toggle-password {
                                    position: absolute;
                                    top: 50%;
                                    right: 15px;
                                    transform: translateY(-50%);
                                    cursor: pointer;
                                }

                                .submit-btn {
                                    background-color: $red;
                                    color: $white;
                                    border-radius: 50px;
                                    border: 1px solid transparent;
                                    padding: 15px;
                                    width: 100%;
                                    transition: all .3s ease;

                                    &:hover {
                                        background-color: $white;
                                        border-color: $red;
                                        color: $red;
                                    }
                                }
                            }

                        }

                        .login-error {
                            position: absolute;
                            bottom: -35px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                    #{$p}-register {
                        margin-top: 50px;
                        color: #5E5E5E;
                        width: 100%;
                        text-align: center;

                        a {
                            color: $hitam;
                            text-decoration: underline;

                            &:hover {
                                color: $red;
                            }
                        }
                    }
                }

                &.brochure {
                    display: flex;
                    flex-direction: column;
                    background-color: $white;
                    border-radius: 20px;
                    overflow: hidden;
                    height: 100%;
                    #{$p}-content {
                        height: inherit;
                    }
                    #{$p}-text {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-center;
                        justify-content: center;
                        padding: 60px;
                        @include media-breakpoint-down(sm) {
                            padding: 40px;
                        }
                    }
                    #{$p}-logo {
                        order: -1;
                        margin: 0 auto 40px;
                    }
                    #{$p}-title {
                        font-size: 35px;
                        font-weight: 700;
                        @include media-breakpoint-down(sm) {
                            font-size: 24px;
                        }
                    }
                    #{$p}-desc {
                        font-size: 16px;
                        color: #5E5E5E;
                    }
                    #{$p}-cover {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        @include media-breakpoint-down(md) {
                            max-height: 250px;
                        }
                        @include media-breakpoint-down(sm) {
                            max-height: 150px;
                        }
                    }
                    #{$p}-form {
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        row-gap: 25px;
                        position: relative;

                        .field-group {
                            display: flex;
                            flex-direction: column;
                            row-gap: 5px;
                            width: 100%;

                            label {
                                font-size: 16px;
                                color: #5E5E5E;
                            }

                            .switch-form {
                                text-align: right;
                                color: #5E5E5E;
                            }

                            .field-input {
                                position: relative;

                                input {
                                    width: 100%;
                                    background-color: #F4F4F4;
                                    border-radius: 5px;
                                    border: none;
                                    padding: 15px;
                                    outline: none;
                                    border: 1px solid transparent;
                                    transition: border .3s ease;

                                    &:focus {
                                        border-color: $red;
                                    }
                                }
    
                                .toggle-password {
                                    position: absolute;
                                    top: 50%;
                                    right: 15px;
                                    transform: translateY(-50%);
                                    cursor: pointer;
                                }

                                .submit-btn {
                                    background-color: $red;
                                    color: $white;
                                    border-radius: 50px;
                                    border: 1px solid transparent;
                                    padding: 15px;
                                    width: 100%;
                                    transition: all .3s ease;

                                    &:hover {
                                        background-color: $white;
                                        border-color: $red;
                                        color: $red;
                                    }
                                }
                            }

                        }

                        .login-error {
                            position: absolute;
                            bottom: -35px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                    #{$p}-register {
                        margin-top: 50px;
                        color: #5E5E5E;
                        width: 100%;
                        text-align: center;

                        a {
                            color: $hitam;
                            text-decoration: underline;

                            &:hover {
                                color: $red;
                            }
                        }
                    }
                }
            }
        }
    }
}

.ginner-container .ginlined-content {
    padding: 0;
    border-radius: 20px;
    overflow: hidden;
}
.ginner-container .gslide-inline {
    border-radius: 20px;
    overflow: hidden;
}