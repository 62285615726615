.section-title {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 140px;
    text-align: center;
    position: relative;
    &.bg-image {
        box-shadow: inset 0 0 0 300px rgba(0, 0, 0, 0.6);
    }
    .descriptions {
        width: 60%;
        margin: auto;
        @include media-breakpoint-down(sm){
            width: 80%;
        }
    }
    @include media-breakpoint-only(md){
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @include media-breakpoint-down(sm){
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.text-container {
    padding: 80px 0px 60px 0px;
    text-align: center;
    @include media-breakpoint-down(sm) {
        padding: 0px;
    }
    .descriptions {
        width: 60%;
        margin: auto;
        @include media-breakpoint-down(sm){
            width: 80%;
        }
    }
}