footer {
    position: relative;
    width: 100%;
    background-color: $soft-blue;
    color: white;
    padding-bottom: 1rem;
    .newsletter {
        padding: 60px 0px;
        background-color: #17171E;
        .row {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px;
        }
        .newsletter-container {
            display: flex;
            align-items: center;
            justify-content: center;
            row-gap: 20px;
            @include media-breakpoint-down(md){
                padding: 0px;
                flex-direction: column;
                align-items: flex-start;
            }
            .newsletter-title {
                text-transform: uppercase;
                color: $white;
                margin: 0px 30px;
                font-size: 1rem;
                font-weight: 400;
                letter-spacing: 2px;
                @include media-breakpoint-down(md) {
                    margin: 0;
                }
            }
            .subscribe {
                width: 100%;
                .section-form {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0px;
                    gap: 15px;
                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                    }
                    .form-group {
                        @include media-breakpoint-down(md) {
                            width: 100%;
                        }
                        div {
                            position: relative;
                        }
                    }
                    .mt_form_message {
                        color: rgb(79, 208, 62);
                        margin-bottom: 0;
                    }
                    .btn-form {
                        height: 70px;
                        background-color: #ED0E15;
                        color: $white;
                        padding: 20px 60px;
                        transition: .2s ease;
                        cursor: pointer;
                        border: none;
                        outline: none;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        transition: all .3s ease;
                        -webkit-transition: all .3s ease;
                        -moz-transition: all .3s ease;
                        -ms-transition: all .3s ease;
                        -o-transition: all .3s ease;
                        @include media-breakpoint-only(md)
                        {
                            padding: 20px 40px;
                        }
                        @include media-breakpoint-down(md) {
                            width: 100%;
                        }
                        @include media-breakpoint-down(sm){
                            padding: 20px;
                        }
                        &:hover{
                            background-color: #8d1820;
                        }
                    }
                }
            }
        }
    }
    .sitemap {
        background-color: $soft-blue;
        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 40px 0px;
            width: 100%;
            color: $white;
            letter-spacing: 2px;
            border-bottom: 1.5px solid #ffffff60;
            flex-direction: column;
            @include media-breakpoint-down(md){
                padding: 20px 0px;
            }
            .sitemap-title {
                color: $white;
                margin: 0px 15px;
                cursor: pointer;
                transition: all .4s linear;
                -webkit-transition: all .4s linear;
                -moz-transition: all .4s linear;
                -ms-transition: all .4s linear;
                -o-transition: all .4s linear;
                i{
                    transition: all .4s linear;
                    -webkit-transition: all .4s linear;
                    -moz-transition: all .4s linear;
                    -ms-transition: all .4s linear;
                    -o-transition: all .4s linear;
                }
                &.open{
                    i{
                        transform: rotate(-180deg);
                        -webkit-transform: rotate(-180deg);
                        -moz-transform: rotate(-180deg);
                        -ms-transform: rotate(-180deg);
                        -o-transform: rotate(-180deg);
                    }
                }
                &:hover{
                    color: rgba(255, 255, 255, 0.6);
                }
            }
            #sitemap{
                width: 100%;
                display: none;
                transition: all .4s linear;
                -webkit-transition: all .4s linear;
                -moz-transition: all .4s linear;
                -ms-transition: all .4s linear;
                -o-transition: all .4s linear;
                .accordion-body{
                    padding-top: 60px;
                    padding-right:  2.25rem;
                    padding-left:  2.25rem;
                }
                .site-map-contents{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    column-gap: 30px;
                    row-gap: 20px;
                    ul{
                        list-style: none;
                        &.main-menu{
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 41px;
                            text-align: left;
                            padding-left: 0px;
                        }
                    }
                    .menu-sub{
                        @include media-breakpoint-up(lg) {
                            padding-left: 22px;
                        }
                        .title{
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 28px;
                            letter-spacing: 0.1em;
                            text-transform: uppercase;
                            text-align: left;
                            transition: all .3s ease;
                            -webkit-transition: all .3s ease;
                            -moz-transition: all .3s ease;
                            -ms-transition: all .3s ease;
                            -o-transition: all .3s ease;
                        }
                        ul{
                            padding: 0px;
                            li{
                                text-align: left;
                                a{
                                font-weight: 300;
                                font-size: 14px;
                                line-height: 30px;
                                color: rgba(255, 255, 255, 0.6);
                                text-align: left;
                                transition: all .3s ease;
                                -webkit-transition: all .3s ease;
                                -moz-transition: all .3s ease;
                                -ms-transition: all .3s ease;
                                -o-transition: all .3s ease;
                                &:hover{
                                    color: #FFF;
                                }
                                }
                                &.child{
                                    margin-left: 30px;
                                }
                            }
                        }
                    }
                    @include media-breakpoint-down(sm) {
                        flex-direction: column;
                    }
                }
            }
        }
    }
    .footer-content {
        padding-top: 70px;
        padding-bottom: 70px;
        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
        }
        @include media-breakpoint-up(lg) {
            padding-left: 60px;
            padding-right: 60px;
        }
        @include media-breakpoint-down(xs){
            padding-bottom: 70px;
        }
        @media screen and (max-width: 389px) {
            padding-bottom: 150px;
        }
        .section-left {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            row-gap: 20px;
            h6.title {
                font-size: 1rem;
                letter-spacing: 2px;
                color: $white;
                text-transform: uppercase;
            }
            .logo img{
                height: auto;
                width: 100%;
                max-width: 150px;
                @include media-breakpoint-down(lg){
                    max-height: 60px;
                    width: auto;
                }
                @include media-breakpoint-down(sm){
                    max-height: 50px;
                    width: auto;
                }
            }
        }
        .section-left {
            .contact-us,
            .visit-us {
                line-height: 35px;
                padding: 0px 30px;
                opacity: .6;
                @include media-breakpoint-down(md){
                    padding: 15px;
                }

                .text-mediumdark p {
                    color: $white;
                }
            }
            .contact-us {
                @include media-breakpoint-up (xl) {
                    padding: 0px;
                }
                @include media-breakpoint-down(md) {
                    margin-bottom: 1.5rem;
                }
                a {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 1rem;
                    font-weight: 300;
                    margin-bottom: 10px;
                    text-decoration: none;
                    transition: all .3s ease;
                    -webkit-transition: all .3s ease;
                    -moz-transition: all .3s ease;
                    -ms-transition: all .3s ease;
                    -o-transition: all .3s ease;
                }
            }
            .logo {
                display: block;
                margin: auto;
                opacity: 1;
            }
        }
        .section-right {
            display: block;
            margin-left: auto;
            .social-media {
                max-width: 80%;
                margin-left: auto;
                display: flex;
                flex-wrap: wrap;
                column-gap: 20px;
                row-gap: 20px;
                justify-content: end;
                margin-right: 15px;
                @include media-breakpoint-down(lg){
                    margin-right: auto;
                    justify-content: center;
                    margin-top: 30px;
                }
                a {
                    color: $soft-blue;
                    font-size: 1.5rem;
                    margin: 0px 10px 20px;
                    width: 2.85714rem;
                    height: 2.85714rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    background-color: rgba(255, 255, 255, 0.7);
                    border-radius: 50%;
                    transition: all .3s ease-in-out;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    -webkit-transition: all .3s ease-in-out;
                    -moz-transition: all .3s ease-in-out;
                    -ms-transition: all .3s ease-in-out;
                    -o-transition: all .3s ease-in-out;
                    i {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        width: 100%;
                    }
                    svg {
                        width: 30px;

                        path {
                            fill: #4e5256 !important;
                        }
                    }
                    &:hover {
                        background-color: $white;
                    }
                }
                @include media-breakpoint-down(xs) {
                    max-width: 100%;
                }
            }
            .bottom-content {
                display: flex;
                width: auto;
                margin-left: auto;
                column-gap: 30px;
                position: fixed;
                bottom: 30px;
                right: 60px;
                z-index: 100;
                transition: all .3s ease;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -ms-transition: all .3s ease;
                -o-transition: all .3s ease;
                &.bottom-of-page{
                    bottom: 75px;
                    right: 60px;
                    @include media-breakpoint-down(xs){
                        bottom: 45px;
                        right: 50px;
                    }
                }
                @include media-breakpoint-down(xs){
                    display: block;
                    margin-left: auto;
                    bottom: -20px;
                    right: 50px;
                }
                .contact-container {
                    flex: 1;
                    border-radius: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 100%;
                    position: relative;
                    right: -120px;
                    transition: all .3s ease;
                    -webkit-transition: all .3s ease;
                    -moz-transition: all .3s ease;
                    -ms-transition: all .3s ease;
                    -o-transition: all .3s ease;
                    @include media-breakpoint-down(xs){
                        right: -40px;
                    }
                    a.text {
                        position: relative;
                        right: -60px;
                        font-size: 0px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $white;
                        height: 3.8rem;
                        text-transform: uppercase;
                        color: $hitam;
                        border-radius: 60px;
                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                        font-weight: 400;
                        font-size: .89rem;
                        visibility: hidden;
                        opacity: 1;
                        transition: all .3s linear;
                        -webkit-transition: all .3s linear;
                        -moz-transition: all .3s linear;
                        -ms-transition: all .3s linear;
                        -o-transition: all .3s linear;
                        span {
                            display: none;
                            transition: all .3s linear;
                            -webkit-transition: all .3s linear;
                            -moz-transition: all .3s linear;
                            -ms-transition: all .3s linear;
                            -o-transition: all .3s linear;
                        }
                        @include media-breakpoint-down(md){
                            height: 2.85714rem;
                            right: -45px;
                        }
                    }
                    &:hover {
                        a.text {
                            width: auto;
                            padding: 0px 90px 0px 30px;
                            visibility: visible;
                            opacity: 1;
                            span {
                                display: block;
                            }
                            &:hover {
                                color: #ED0E15;
                            }
                            @include media-breakpoint-down(md){
                                padding: 0px 75px 0px 30px;
                            }
                        }
                    }
                    a.contact {
                        color: $white;
                        font-size: 2rem;
                        margin: 0px;
                        width: 3.8rem;
                        height: 3.8rem;
                        display: inline-block;
                        background-color: #25D366;
                        border-radius: 50%;
                        transition: all .3s ease-in-out;
                        border-radius: 50%;
                        z-index: 10;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;
                        -webkit-transition: all .3s ease-in-out;
                        -moz-transition: all .3s ease-in-out;
                        -ms-transition: all .3s ease-in-out;
                        -o-transition: all .3s ease-in-out;
                        @include media-breakpoint-down(md)
                        {
                            width: 2.85714rem;
                            height: 2.85714rem;
                        }
                        i {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
                .to-top-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    cursor: pointer;
                    position: relative;
                    right: -30px;
                    transform: translateY(120px);
                    -webkit-transform: translateY(120px);
                    -moz-transform: translateY(120px);
                    -ms-transform: translateY(120px);
                    -o-transform: translateY(120px);
                    transition: all .3s ease-in-out;
                    -webkit-transition: all .3s ease-in-out;
                    -moz-transition: all .3s ease-in-out;
                    -ms-transition: all .3s ease-in-out;
                    -o-transition: all .3s ease-in-out;
                    @include media-breakpoint-down(xs){
                        right: -40px;
                        width: max-content;
                        margin-left: auto;
                    }
                    .to-top {
                        color: $white;
                        font-size: 2rem;
                        margin: 0px;
                        width: 3.8rem;
                        height: 3.8rem;
                        background-color: #2A314B;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;
                        transition: all .6s ease;
                        -webkit-transition: all .6s ease;
                        -moz-transition: all .6s ease;
                        -ms-transition: all .6s ease;
                        -o-transition: all .6s ease;
                        svg{
                            path{
                                transition: all .3s ease;
                                -webkit-transition: all .3s ease;
                                -moz-transition: all .3s ease;
                                -ms-transition: all .3s ease;
                                -o-transition: all .3s ease;
                            }
                        }
                        @include media-breakpoint-down(md){
                            width: 2.85714rem;
                            height: 2.85714rem;
                            svg{
                                height: 20px;
                            }
                        }
                        &:hover{
                            background-color: #FFF;
                            box-shadow: 1px 1px 20px -6px rgba(42, 49, 75,0.4);
                            -webkit-box-shadow: 1px 1px 20px -6px rgba(42, 49, 75,0.4);
                            -moz-box-shadow: 1px 1px 20px -6px rgba(42, 49, 75,0.4);
                            svg{
                                path{
                                    stroke: rgb(42, 49, 75);
                                }
                            }
                        }
                    }
                }
                &.standar{
                    .contact-container{
                        transform: translateX(-80px);
                        -webkit-transform: translateX(-80px);
                        -moz-transform: translateX(-80px);
                        -ms-transform: translateX(-80px);
                        -o-transform: translateX(-80px);
                        @include media-breakpoint-down(xs){
                            transform: translateY(-60px);
                            -webkit-transform: translateY(-60px);
                            -moz-transform: translateY(-60px);
                            -ms-transform: translateY(-60px);
                            -o-transform: translateY(-60px);
}
                    }
                    .to-top-container{
                    transform: translateY(0px);
                    -webkit-transform: translateY(0px);
                    -moz-transform: translateY(0px);
                    -ms-transform: translateY(0px);
                    -o-transform: translateY(0px);
                    @include media-breakpoint-down(xs){
                        transform: translateY(-40px);
                        -webkit-transform: translateY(-40px);
                        -moz-transform: translateY(-40px);
                        -ms-transform: translateY(-40px);
                        -o-transform: translateY(-40px);
                    }
                    }
                }
            }
        }
    }
    .copyrights {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        p {
            color: $white;
            font-size: .875rem;
            margin: 0px;
            opacity: .6;
        }
        @media screen and (max-width: 389px) {
            margin-bottom: 10px;
            p {
                padding: 0px 15px;
            }
        }
    }
}