.sidebar-menu {
   position: relative;
   background-color: $white;
   box-shadow: 0 3px 15px rgba(0, 0, 0, .1);
   h4 {
      margin-bottom: 0;
      font-size: 1.125rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding: 1.5rem;
      text-align: center;
      border-bottom: 1px solid rgba(112, 112, 112, .2);
   }
   .sidemenu {
      list-style: none;
      padding: 1rem 0;
      margin: 0;
      li {
         display: block;
         &.current {
            .side__link {
               border-color: $primary;
               color: $primary;
               font-weight: 700;
            }
         }
         .side__link {
            display: block;
            font-size: 1rem;
            padding: .5rem 2rem;
            color: #333;
            border-left: 6px solid transparent;
            transition: color .3s ease-in-out;
            &:hover {
               color: $primary;
            }
         }
         .list-group {
            padding: 0 2rem;
            border: none;
            .list-group-item {
               display: block;
               background: none;
               font-size: .875rem;
               border: none;
               color: #333;
               padding: .125rem 1rem;
               &.active {
                  color: $primary;
                  font-weight: 700;
               }
            }
         }
      }
   }
   .menu-accordion {
      list-style: none;
      padding: 1rem 0;
      margin: 0;
      border-radius: 0;
      li {
         display: block;
         &.current {
            .acc__link {
               border-color: $primary;
               color: $primary;
               font-weight: 700;
            }
            .current {
               a {
                  color: $primary;
                  font-weight: 700;
               }
            }
         }
         .acc__link {
            display: block;
            font-size: 1rem;
            padding: .5rem 2rem;
            color: #333;
            border-left: 6px solid transparent;
            transition: color .3s ease-in-out;
            &:hover {
               color: $primary;
            }
         }
      }
      ul {
         display: none;
         flex-direction: column;
         padding: 0 2rem;
         border: none;
         border-radius: 0;
         margin-bottom: 0;
         li {
            a {
               display: block;
               background: none;
               font-size: .875rem;
               border: none;
               color: #333;
               padding: .125rem 1rem;
               &.active {
                  color: $primary;
                  font-weight: 700;
               }
            }
         }
      }
   }
}

.sidebar-mobile {
   position: absolute;
   top: -150px;
   left: 0;
   right: 0;
   width: calc(100% - 4rem);
   margin: 0 auto;
   background-color: $white;
   box-shadow: 0 3px 15px rgba(0, 0, 0, .1);
   h4 {
      margin-bottom: 0;
      font-size: 1.125rem;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding: 1.5rem;
      text-align: center;
      border-bottom: 1px solid rgba(112, 112, 112, .2);
      @include media-breakpoint-down(sm) {
         font-size: .875rem;
         padding: 1rem 1.5rem;
      }
   }
}