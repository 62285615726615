.home-applications {
    padding: 0px !important;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
        max-height: 750px;
    }
    @include media-breakpoint-only(lg) {
        max-height: 700px;
    }
    @include media-breakpoint-down(md) {
        max-height: unset;
    }
    #home-aplications-sliders-mw{
        height: 100% !important;
    }
    .row {
        margin: 0px;
        .image-content {
            padding: 0px;
            @include media-breakpoint-up(lg){
                .home-aplications-sliders {
                    transform: translateY(150px);
                }
            }
            .items {
                height: 500px !important;
                @include media-breakpoint-down(lg) {
                    height: 400px !important;
                }
                @include media-breakpoint-down(md) {
                    height: 340px !important;
                }
                @include media-breakpoint-down(sm) {
                    height: 240px !important;
                }
                .images {
                    height: 100%;
                }
                .slider-content {
                    width: 100%;
                    height: 100%;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    transition: all 1s ease !important;
                    -webkit-transition: all 1s ease !important;
                    -moz-transition: all 1s ease !important;
                    -ms-transition: all 1s ease !important;
                    -o-transition: all 1s ease !important;
                    animation: applicationUp 1s ease 0s 1 normal forwards;
                    -webkit-animation: applicationUp 1s ease 0s 1 normal forwards;
                    @keyframes applicationUp {
                        0% {
                          opacity: 0;
                          transform: translateY(250px);
                        }
                      
                        100% {
                          opacity: 1;
                          transform: translateY(0);
                        }
                      }
                    .slider-title {
                        display: flex;
                        color: $white;
                        margin: 0px;
                        font-size: 20px;
                        width: fit-content;
                        background-color: $soft-blue;
                        padding: 10px 20px;
                        transition: all .4s ease .4s;
                        -webkit-transition: all .4s ease .4s;
                        -moz-transition: all .4s ease .4s;
                        -ms-transition: all .4s ease .4s;
                        -o-transition: all .4s ease .4s;
                        visibility: hidden;
                        opacity: 0;
                    }
                }
                &.swiper-slide{
                    .slider-content.active-index {
                        .slider-title{
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .controls-slider-prev, .controls-slider-next{
            bottom: 44%;
            display: none;
            @include media-breakpoint-down(lg){
                display: block;
            }
        }
        .controls-slider-prev{
            left: 12%;
        }
        .controls-slider-next{
            right: 12%;
        }
        .text-content {
            background-color: $white;
            z-index: 5;
            display: block;
            @include media-breakpoint-up(lg) {
                max-height: 750px;
            }
            @include media-breakpoint-only(lg) {
                max-height: 700px;
            }
            @include media-breakpoint-down(md) {
                max-height: unset;
            }
            .text-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 0px 100px 0px 30px;
                text-align: left;
                @include media-breakpoint-down(md){
                    height: auto;
                    text-align: center;
                    padding: 60px 0px 40px 0px;
                    .sub-title, .title, .link{
                        text-align: center;
                        width: 100%;
                    }
                    .link{
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}