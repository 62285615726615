.promo {
    .promo-banner {
        overflow: hidden;
        img {
            width: 100vw;
            height: auto;
        }
    }
    .list-promo {
        .row {
            padding: 40px 0px 40px 0px;
            margin: 0px;
            border-top: 1px solid $soft-blue;
            .list-items {
                margin-bottom: 30px;
                text-decoration: none;
                .custom-card {
                    background: $white;
                    border-radius: 20px;
                    height: 100%;
                    border: 1px solid #f1f1f1;
                    overflow: hidden;
                    transition: all .3s ease;
                    -webkit-transition: all .3s ease;
                    -moz-transition: all .3s ease;
                    -ms-transition: all .3s ease;
                    -o-transition: all .3s ease;
                    &:hover {
                        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
                    }
                    .promo-image {
                        height: 300px;
                        width: auto;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        @include media-breakpoint-only(lg) {
                            height: 235px;
                        }
                        @include media-breakpoint-down(md) {
                            height: 260px;
                        }
                        @include media-breakpoint-down(xs) {
                            height: 235px;
                        }
                        @media screen and (min-width: 390px) and (max-width: 490px){
                            height: 190px;
                        }
                        @media screen and (min-width: 320px) and (max-width: 390px) {
                            height: 155px;
                        }
                        @media screen and (max-width: 320px) {
                            height: 120px;
                        }
                    }
                    .promo-details {
                        padding: 20px;
                        .promo-title {
                            margin-bottom: 5px;
                            @include media-breakpoint-down(xs) {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
        .btn.show-all {
            display: block;
            width: max-content;
            margin: 0px auto 60px auto;
            padding: 15px 20px;
            background-color: #FFF;
            border: 1px solid #4e5256;
            outline: none;
            box-sizing: border-box;
            color:#4e5256;
            transition: all .3s ease;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            -o-transition: all .3s ease;
            &:hover {
                border: 1px solid $red;
                color:$red;
            }
        }
        .text-details {
            padding: 40px 0px 80px 0px;
            border-top: 1px solid $soft-blue;
            .list-button {
                display: block;
                margin-top: 40px;
                .btn-download {
                    padding: 15px 30px;
                    margin-right: 10px;
                    font-weight: 300;
                    width: 100%;
                }
            }
        }
        .back {
            text-transform: uppercase;
            text-decoration: none;
            color: $black;
            font-size: 14px;
            line-height: normal;
            font-weight: 600;
            letter-spacing: .2em;
            margin-bottom: 2rem;
            display: block;
            width: max-content;
            margin-top: 10px;
            @include media-breakpoint-down (sm) {
                font-size: 12px;
                margin-bottom: 1.5rem;
            }
            transition: all .3s ease;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            -o-transition: all .3s ease;
            &:hover {
                color: $red;
            }
        }
    }
}