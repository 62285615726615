%imageCover {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.dashboard-divider {
   margin-left: -2rem;
   margin-right: -2rem;
   margin-top: 2rem;
   margin-bottom: 3rem;
   background-color: rgba(112, 112, 112, .3);
}

.dashed-line {
   background-color: transparent;
   border-top: 2px dashed rgba(112, 112, 112, .3);
}