.register {
    padding-top: 110px !important;
    padding-bottom: 0 !important;
    @include media-breakpoint-down(md) {
        padding-top: 60px !important;
    }

    .container-fluid {
        padding: 0;
    }

    #{&}-content {
        padding: 50px 0 80px;
        @include media-breakpoint-down(md) {
            padding: 50px 20px 80px;
        }

        &__head {
            max-width: 550px;
            margin-bottom: 35px;
            @include media-breakpoint-down(md) {
                margin-bottom: 0;
                max-width: 100%;
            }

            .head-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    align-items: flex-start;
                    margin-bottom: 35px;
                }

                h1 {
                    margin-bottom: 10px;
                }

                a {
                    color: $hitam;
                    text-decoration: underline;
                    &:hover {
                        color: $red;
                    }
                }

                .products-single__content-bim--popup {
                    display: none;
                }
            }

        }

        #registerform {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 550px;
            row-gap: 25px;
            position: relative;
            @include media-breakpoint-down(md) {
                max-width: 100%;
            }
    
            .field-wrapper {
                &.multicolumn {
                    display: flex;
                    gap: 25px;
                    @include media-breakpoint-down(lg) {
                        flex-direction: column;
                    }
                }
            }
    
            .field-group {
                display: flex;
                flex-direction: column;
                row-gap: 5px;
                width: 100%;
    
                label {
                    font-size: 16px;
                    color: #5E5E5E;
                }
    
                .switch-form {
                    text-align: right;
                    color: #5E5E5E;
                }
    
                .field-input {
                    position: relative;
    
                    input,
                    select {
                        width: 100%;
                        background-color: $white;
                        border-radius: 5px;
                        border: none;
                        padding: 15px;
                        outline: none;
                        border: 1px solid transparent;
                        transition: border .3s ease;
    
                        &:focus {
                            border-color: $red;
                        }
                    }
    
                    .toggle-password {
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        transform: translateY(-50%);
                        cursor: pointer;
                    }
    
                    .submit-btn {
                        background-color: $red;
                        color: $white;
                        border-radius: 50px;
                        border: 1px solid transparent;
                        padding: 15px;
                        width: 100%;
                        transition: all .3s ease;
    
                        &:hover {
                            background-color: $white;
                            border-color: $red;
                            color: $red;
                        }
                    }
                }
    
            }
        }
    }

    #{&}-cover {

        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include media-breakpoint-down(md) {
                max-height: 300px;
            }
            @include media-breakpoint-down(md) {
                max-height: 200px;
            }
        }
    }

}