.about-us-history{
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(md){
        .text-container{
            padding: 60px 0px;
        }
    }
    .contents{
        position: relative;
        &:before {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 3px;
            background: #000000;
            content: '';
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
        .milestones-list{
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        flex: 1;
        margin-top: 4.4em;
        margin-bottom: 2em;
        .swiper-slide{
            display: flex;
            align-items: center;
            justify-content: center;
            .milestone-key {
                position: relative;
                color: #ccc;
                background-color: transparent;
                outline: none;
                border: none;
                top: 3px;
                @include media-breakpoint-down(md){
                    top: 0px;
                }
                .milestone-key-year {
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    padding-bottom: 20px;
                    font-size: 20px;
                    line-height: 26px;            
                    text-align: center;
                    color: #ACACAC;
                    font-weight: 300;
                    -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
                    width: max-content;
                    @include media-breakpoint-down(md){
                        font-size: 16px;
                    }
                    @include media-breakpoint-down(xs){
                        max-width: 72px;
                    }
                }
                .milestone-key-dot {
                    display: block;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background: #ACACAC;
                    @include media-breakpoint-down(md){
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            &.swiper-slide-thumb-active{
                top: -4px;
                .milestone-key:after {
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    width: 0;
                    height: 0;
                    margin-top: -15px;
                    border-color: transparent transparent #4e5256 transparent;
                    border-style: solid;
                    border-width: 30px;
                    content: '';
                    opacity: 1;
                    -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
                    @include media-breakpoint-down(sm){
                        margin-top: -6px;
                    }
                }
                .milestone-key-year{
                    font-weight: 700;
                    color: #4e5256;
                }
                .milestone-key-dot{
                    background: #4e5256;
                    width: 43px;
                    height: 43px;
                    @include media-breakpoint-down(md){
                        width: 23px;
                        height: 23px;
                    }
                }
            }
        }
        }
    }
    .slider-content{
        background-color: $soft-blue;
        padding: 60px 0px 140px 0px;
        @include media-breakpoint-down(md){
            padding: 60px 20px 140px 20px;
        }
        .history-slider{
            max-width: 75%;
            margin: auto;
            position: relative;
            overflow: hidden;
            @include media-breakpoint-down(md){
                overflow: visible;
            }
            .list-items{
                .title{
                    font-weight: 600;
                    font-size: 25px;
                    line-height: 44px;
                    color: $white;
                    text-align: center;
                }
                .desc{
                    color: $white;
                    text-align: center;
                    width: 80%;
                    margin: auto;
                    @include media-breakpoint-down(md){
                        width: 90%;
                    }
                }
            }
        .history-button-prev{
            position: absolute;
            left: 0px;
            top: 25%;
            padding: 15px;
            cursor: pointer;
            z-index: 10;
            &.swiper-button-disabled{
                svg {
                    path{
                        opacity: .5;
                    }
                }
            }
            @include media-breakpoint-down(md){
                left: -20%;
            }
        }
        .history-button-next{
            position: absolute;
            right: 0px;
            top: 25%;
            padding: 15px;
            cursor: pointer;
            z-index: 10;
            &.swiper-button-disabled{
                svg {
                    path{
                        opacity: .5;
                    }
                }
            }
            @include media-breakpoint-down(md){
                right: -10%;
            }
        }
        }
        .swiper-wrapper {
            min-height: 90px;
            align-items: center;
        }
    }
    .custom-swiper-pagination{
        bottom: 4rem;
        .swiper-pagination-bullet{
            border: 2px solid #4e5256;
            -webkit-box-shadow: 0 0 0 2px #FFF;
            box-shadow: 0 0 0 2px #FFF;
            &.swiper-pagination-bullet-active{
                background-color: #FFF;
            }
        }
    }
}