.home-our-products {
    padding-bottom: 280px !important;
    padding-top: 0px !important;
    overflow: hidden;
    position: relative;
    &::before{
        position: absolute;
        content: '';
        background-color: #3A474F;
        background-image: url('../images/light-gray-concrete-wall-pattern.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 58%;
        z-index: 0;
    }
    @include media-breakpoint-down(md){
        padding: 0px 0px 180px 0px !important;
    }
    .product-slider{
        position: relative;
        max-width: 90%;
        margin: auto;
        @include media-breakpoint-down(sm){
            max-width: 100%;
        }
        .card-list{
            max-width: 80%;
            margin: auto;
            overflow: hidden;
            .swiper-slide:not(.swiper-slide-active){
                .card-link{
                    display: none !important;
                }
            }
            @include media-breakpoint-down(md){
                max-width: 100%;
            }
        }
        @include media-breakpoint-up(lg){
            .custom-swiper-pagination {
                bottom: -4.5rem;
            }
        }
        @include media-breakpoint-only(md){
            .controls-slider-prev{
                left: 15%;
                bottom: -4.5rem;
            }
            .controls-slider-next{
                right: 15%;
                bottom: -4.5rem;
            }
        }
        @include media-breakpoint-down(md) {
            .custom-swiper-pagination {
                display: flex;
                opacity: 1;
            }
        }
        @include media-breakpoint-down(sm){
            .controls-slider-prev{
                left: 5%;
                bottom: -4.5rem;
            }
            .controls-slider-next{
                right: 5%;
                bottom: -4.5rem;
            }
        }
    }
    .text-container {
        text-align: center;
        margin: auto;
        color: $white;
        position: relative;
        z-index: 2;
        @include media-breakpoint-down(md){
            padding: 60px 0px 40px 0px;
        }
        .sub-title.dark {
            color: rgba($color: #fff, $alpha: .6);
        }
        .title.light{
            width: 75%;
            margin: auto;
            margin-bottom: 30px;

            @include media-breakpoint-down(md) {
                width: 85%;
            }
        }
    }
    .link-container{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -160px;
        width: 100%;
    }
}