.home-about-us{
    .row{
        display: flex;
        align-items: center;
        .sub-title {
            @include media-breakpoint-down(md) {
                text-align: left;
            }
        }
        .text-container{
            text-align: left;
            @include media-breakpoint-down(md){
                margin-bottom: 0px;
                padding: 0px;
            }
            p{
                margin-bottom: 15px;
            }
        }
        .right-content{
            display: flex;
            justify-content: center;
            align-items: center;
            row-gap: 30px;
            .items{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                row-gap: 30px;
                .icon{
                    max-width: 150px;
                    max-height: 150px;
                }
                .image-desc{
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                }
            }

            iframe {
                width: 100%;
                height: 430px;
                @include media-breakpoint-down(md) {
                    height: 200px;
                }
            }
        }
    }
}