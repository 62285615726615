.contact-us-brances {
    width: 80%;
    margin: auto;
    padding-top: 0px !important;
    overflow: hidden;
    @include media-breakpoint-down(md){
        width: 100%;
    }
    .title-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        hr {
            flex: 1
        }
        .title {
            margin: 0px 20px;
            font-weight: 600;
            font-size: 30px;
            line-height: 44px;
            text-align: center;
            @include media-breakpoint-down(sm){
                font-size: 25px;
            }
        }
    }
    .brances {
        padding: 20px;
        .card-custom {
            background: $white;
            box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            height: 100%;
            min-height: 320px;
            display: flex;
            @include media-breakpoint-up(sm){
                position: relative;
            }
            @include media-breakpoint-down(sm){
                display: block;
            }
            .brances-image {
                width: 30%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 20px 0px 0px 20px;
                -webkit-border-radius: 20px 0px 0px 20px;
                -moz-border-radius: 20px 0px 0px 20px;
                -ms-border-radius: 20px 0px 0px 20px;
                -o-border-radius: 20px 0px 0px 20px;
                @include media-breakpoint-only(md) {
                    width: 75%;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    height: 200px;
                    border-radius: 20px 20px 0px 0px;
                    -webkit-border-radius: 20px 20px 0px 0px;
                    -moz-border-radius: 20px 20px 0px 0px;
                    -ms-border-radius: 20px 20px 0px 0px;
                    -o-border-radius: 20px 20px 0px 0px;
                }
            }
            .text-container {
                padding: 30px;
                text-align: left;
                @include media-breakpoint-down(sm){
                    padding: 20px;
                }
                .brances-name {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 26px;
                    text-align: left;
                }
                .address {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 26px;
                    margin-bottom: 40px;
                    text-align: left;
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 20px;
                    }
                }
                a.link {
                    margin: 1rem 0px;
                }
                .contact-list {
                    margin-bottom: 40px;
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 20px;
                    }
                    .contact-detail {
                        margin-bottom: 5px;
                        text-align: left;
                    }
                }
            }
        }
    }
}