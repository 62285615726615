section.details {
    padding-top: 0px !important;
    .section-title {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 110px;
        padding-bottom: 140px;
        text-align: center;
        position: relative;
        box-shadow: inset 0 0 0 300px rgba(0, 0, 0, 0.6);
        @include media-breakpoint-down(md){
            padding-bottom: 90px;
        }
        @include media-breakpoint-up(lg){
            .sub-title{
                margin-top: 60px;
            }
        }
    }
    .list-sub-category{
        padding: 80px 0px;
        .card-list{
            width: 75%;
            margin: auto;
            @include media-breakpoint-down(lg){
                width: 100%;
            }
            .card{
                margin-bottom: 20px;
            }
        }
    }
    .navigation {
        display: flex;
        align-items: center;
        position: relative;
        background-color: $white;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        width: 90%;
        margin: auto;
        top: -45px;
        overflow: hidden;
        .controls-button {
            position: absolute;
            top: 0px;
            bottom: 0px;
            display: flex;
            align-items: center;
            z-index: 10;
            cursor: pointer;
            width: 5%;
            &:not([aria-disabled="true"]) {
                width: 20%;
            }
            &[data-controls="next"] {
                right: 0px;
                justify-content: flex-end;
                background: rgb(255,255,255);
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,1) 100%);
                svg {
                    position: relative;
                    right: 20px;
                }
            }
            &[data-controls="prev"] {
                left: 0px;
                justify-content: flex-start;
                background: rgb(255,255,255);
                background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%);
                svg {
                    position: relative;
                    left: 20px;
                }
            }
            &[aria-disabled="true"]{
                opacity: .5;
            }
        }
        .menu-slider {
            flex: 1;
            .nav {
                min-width: 100% !important;
                .nav-items {
                    transition: all .3s ease;
                    -webkit-transition: all .3s ease;
                    -moz-transition: all .3s ease;
                    -ms-transition: all .3s ease;
                    -o-transition: all .3s ease;
                    a{
                        padding: 25px 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                    }
                    &:not(:last-child) {
                        border-right: 1px solid rgba(0, 0, 0, 0.15);
                    }
                    .nav-title {
                        margin-left: 15px;
                        margin-bottom: 0px;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                    }
                    img {
                        height: 36px;
                        width: auto;
                    }
                    &:hover{
                        background-color: rgb(238, 238, 238);
                    }
                }
            }
        }
    }
    select{
        height: 60px;
        display: flex;
        align-items: center;
        position: relative;
        background-color: $white;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        width: 90%;
        margin: auto;
        top: -30px;
        overflow: hidden;
        background-image: url("../images/icons/custom-select-arrow.svg");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 20px);
        background-position-y: 50%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 20px 30px;
        flex: 1;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: capitalize;
        color: $hitam;
        outline: none;
        border: none;
        @include media-breakpoint-down(md){
            background-position-x: calc(100% - 15px);
        }
    }
    .list-details {
        margin-bottom: 60px;
        overflow: hidden;
        .items {
            padding-top: 40px;
            margin-bottom: 60px;
            &:not(.list-products){
                padding-bottom: 90px;
            }
            @include media-breakpoint-only(md){
                padding-top: 30px;
                margin-bottom: 30px;
            }
            @include media-breakpoint-down(sm){
                padding-top: 10px;
                margin-bottom: 30px;
            }
            .application-titles {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 30px;
                margin-bottom: 50px;
                @include media-breakpoint-only(md){
                    column-gap: 20px;
                    margin-bottom: 30px;
                }
                @include media-breakpoint-down(sm){
                    column-gap: 15px;
                    margin-bottom: 15px;
                }
                .title {
                    font-weight: 600;
                    font-size: 45px;
                    line-height: 67px;
                    margin: 0px;
                    @include media-breakpoint-only(md){
                        font-size: 35px;
                    }
                    @include media-breakpoint-down(sm){
                        font-size: 28px;
                    }
                }
                img {
                    height: 85px;
                    width: auto;
                    @include media-breakpoint-only(md){
                        height: 50px;
                    }
                    @include media-breakpoint-down(sm){
                        height: 40px;
                    }
                }
                hr {
                    flex: 1;
                }
            }
            .product-certifications{
                display: flex;
                flex-wrap: wrap;
                row-gap: 30px;
                justify-content: center;
                margin-bottom: 80px;
                @include media-breakpoint-down(sm){
                    margin-bottom: 60px;
                }
                .items{
                    display: flex;
                    flex-direction: column;
                    max-width: 20%;
                    padding: 0px 20px;
                    text-align: center;
                    margin-bottom: 0px;
                    @include media-breakpoint-down(lg){
                        max-width: 30%;
                    }
                    @include media-breakpoint-down(sm){
                        max-width: 50%;
                    }
                    .icon {
                        height: 85px;
                        width: 90px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        margin: 10px auto 30px auto;
                    }
                }
            }
            .details {
                margin-bottom: 60px;
                @include media-breakpoint-down(md){
                    margin-bottom: 30px;
                }
                .row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .descriptions {
                        @include media-breakpoint-up(lg){
                            max-width: 95%;
                            margin-right: auto;
                        }
                        @include media-breakpoint-down(lg){
                            margin-bottom: 30px;
                        }
                    }
                    .btn-download-catalouge {
                        padding: 20px 15px;
                        width: 95%;
                        margin-left: auto;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 16px;
                        text-align: center;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include media-breakpoint-only(lg){
                            margin: 20px auto 0px auto;
                        }
                        @include media-breakpoint-down(md){
                            font-size: 12px;
                            padding: 15px 15px;
                            width: 100%;
                        }
                        svg {
                            margin-left: 10px;
                            .arrow{
                                transition: all .3s linear;
                                -webkit-transition: all .3s linear;
                                -moz-transition: all .3s linear;
                                -ms-transition: all .3s linear;
                                -o-transition: all .3s linear;
                            }
                            @include media-breakpoint-down(md){
                                height: 20px;
                            }
                        }
                        &:hover {
                            .arrow {
                                transform: translateY(5px);
                                -webkit-transform: translateY(5px);
                                -moz-transform: translateY(5px);
                                -ms-transform: translateY(5px);
                                -o-transform: translateY(5px);
}
                        }
                    }
                }
            }
            .featured {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                @include media-breakpoint-down(lg){
                    .container{
                        padding: 0px;
                        max-width: 100%;
                    }
                }
                .featured-title {
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 45px;
                    margin-bottom: 30px;
                    text-align: center;
                    @include media-breakpoint-only(md){
                        font-size: 21px;
                        margin-bottom: 20px;
                    }
                    @include media-breakpoint-down(sm){
                        font-size: 16px;
                        margin-bottom: 10px;
                    }
                }
                .featured-product-container{
                    width: 100%;
                    position: relative;
                    .tns-inner{
                        padding: 5px;
                        @include media-breakpoint-up(xl){
                            .tns-slider {
                                margin-left: auto !important;
                                margin-right: auto !important;
                            }
                        }
                    }
                    @include media-breakpoint-up(xl){
                        .controls-slider-prev{
                            left: -2%;
                        }
                        .controls-slider-next{
                            right: -2%;
                        }
                    }
                    .featured {
                        width: 80%;
                        margin: auto;
                        .card-white {
                            height: 325px;
                        }
                        @include media-breakpoint-down(md){
                            width: 100%;
                        }
                    }
                }
                .featured-navigation {
                    width: 83%;
                    margin-right: auto;
                    margin-left: auto;
                    margin-bottom: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include media-breakpoint-only(md){
                        width: 90%;
                        margin-bottom: 40px;
                    }
                    @include media-breakpoint-down(sm){
                        width: 100%;
                        margin-bottom: 30px;
                    }
                    .featured-menu {
                        width: 100%;
                    }
                    .featured-menu-controls {
                        padding: 15px;
                        cursor: pointer;
                        svg{
                            path{
                            transition: all .3s ease;
                            -webkit-transition: all .3s ease;
                            -moz-transition: all .3s ease;
                            -ms-transition: all .3s ease;
                            -o-transition: all .3s ease;
                            }
                        }
                        &:hover{
                            svg{
                                path{
                                    fill: #c41f26;
                                }
                            }
                        }
                    }
                    .nav {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding: 0px 30px;
                        @include media-breakpoint-down(sm){
                            padding: 0px 10px;
                        }
                        .nav-items {
                            border-bottom: 4px solid transparent;
                            background-color: transparent;
                            border: none;
                            outline: none;
                            transition: all .3s ease 0s;
                            padding: 0px 20px;
                            @include media-breakpoint-down(md){
                                border-bottom: 2px solid transparent;
                            }
                            &.active {
                                .nav-title {
                                    font-weight: 700;
                                    border-bottom: 4px solid $red;
                                }
                            }
                            .nav-title {
                                font-weight: 300;
                                font-size: 20px;
                                line-height: 30px;
                                margin: 10px 0px;
                                width: max-content;
                                margin: auto;
                                @include media-breakpoint-only(md){
                                    font-size: 16px;
                                }
                                @include media-breakpoint-down(sm){
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
                .tab-content {
                    width: 100%;
                    .tab-pane {
                        position: relative;
                        .card-list {
                            width: 80%;
                            margin: auto;
                            @include media-breakpoint-up(lg){
                                .controls-slider-prev{
                                    left: -10%;
                                }
                                .controls-slider-next{
                                    right: -10%;
                                }
                            }
                            .card {
                                height: 325px;
                                background-size: 90%;
                            }
                            @include media-breakpoint-down(md){
                                width: 100%;
                            }
                            .items-slide {
                                @include media-breakpoint-down(md) {
                                    padding: 0px 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}