.latest {
    background-color: $soft-blue;
    margin: 80px 0px;
    @include media-breakpoint-down(lg){
        margin: 60px 0px 40px 0px;
    }
    @include media-breakpoint-down(sm){
        padding-bottom: 60px !important;
    }
    .title {
        font-weight: bold;
        font-size: 35px;
        line-height: 45px;
        text-align: center;
        margin-bottom: 60px;
        @include media-breakpoint-only(md){
            font-size: 30px;
        }
        @include media-breakpoint-down(sm){
            font-size: 22px;
            margin-bottom: 30px;
        }
    }
    .latest-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 480px;
        width: auto;
        border-radius: 20px;
        max-width: 100%;
        @include media-breakpoint-only(md){
            height: 380px;
        }
        @include media-breakpoint-only(sm){
            height: 280px;
        }
        @include media-breakpoint-down(xs){
            height: 200px;
        }
    }
    .latest-text {
        padding: 0px 30px;
        @include media-breakpoint-down(lg){
            padding: 30px 0px 0px 0px;
        }
        a:hover {
            color: rgb(185, 185, 185);
        }
        .timestamp {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 10px;
            color: $white;
            list-style: none;
            padding: 0px;
            .date {
                font-weight: 300;
                font-size: 16px;
                line-height: 16px;
            }
            .category {
                font-weight: 300;
                font-size: 16px;
                line-height: 16px;
                letter-spacing: 0.2em;
                text-transform: uppercase;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                list-style: none;
                padding: 0px;
                li:not(:last-child):after {
                    content: ",";
                }
            }
        }
        .latest-title {
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 44px;
            color: $white;
            transition: all .3s ease;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            -o-transition: all .3s ease;
            @include media-breakpoint-only(md){
                font-size: 22px;
                line-height: 36px;
            }
            @include media-breakpoint-down(sm){
                font-size: 18px;
                line-height: 28px;
            }
        }
        .latest-desc {
            padding-top: 20px;
            @include media-breakpoint-down(sm) {
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            p {
                font-style: normal;
                font-size: 16px;
                line-height: 26px;
                color: $white;
                font-weight: 300;
                opacity: .9;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
        .latest-link {
            font-weight: bold;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            color: $white;
            text-decoration: none;
            transition: all .3s ease;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            -o-transition: all .3s ease;
            i{
                margin-left: 10px;
            }
        }
    }
}