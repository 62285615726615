.group-companies{
    display: none;
    position: relative;
    background-color: #FFF;
    &::before{
        content: '';
        background-color: #FFF;
        width: 100%;
        position: absolute;
    }
    .text-container{
        padding-bottom: 80px;
        background-color: #FFF;
    }
}
.hierarchy{
    overflow: hidden;
    background: #FFF;
    .hierarchy-slider-container{
        position: relative;
        max-width: 90%;
        margin: auto;
        margin-bottom: 40px;
        @include media-breakpoint-only(xl){
            .controls-slider-prev{
                bottom: 40%;
            }
            .controls-slider-next{
                bottom: 40%;
            }
        }
        @include media-breakpoint-down(sm){
            .controls-slider-prev{
                left: 5%;
                bottom: -4.5rem;
            }
            .controls-slider-next{
                right: 5%;
                bottom: -4.5rem;
            }
        }
        @include media-breakpoint-down(sm){
            max-width: 100%;
            padding: 0px;
        }
        .card-list{
            max-width: 80%;
            margin: auto;
            overflow: hidden;
            padding: 10px 0px;
            @include media-breakpoint-down(md){
                max-width: 100%;
                padding: 20px 0;
            }
        }
        .items-slide{
            .white-card{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 370px;
                padding: 15px;
                overflow: hidden;
                background-color: #FFF;
                border-radius: 20px;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border: none;
                overflow: hidden;
                filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
                -webkit-filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
                .logo{
                    width: auto;
                    width: 67%;
                }
                @include media-breakpoint-only(md){
                    height: 360px;
                }
                @include media-breakpoint-only(sm){
                    height: 320px;
                }
                @include media-breakpoint-down(xs){
                    height: 250px;
                }
            }
        }
    }
    .see-hierarchy{
        max-width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 60px;
        hr{
            flex: 1;
        }
        @include media-breakpoint-down(md){
            max-width: 100%;
        }
        .btn-white-outline{
            i{
                margin-left: 10px;
                transition: all .4s linear;
                -webkit-transition: all .4s linear;
                -moz-transition: all .4s linear;
                -ms-transition: all .4s linear;
                -o-transition: all .4s linear;
            }
            &.open{
                i{
                    transform: rotate(-180deg);
                    -webkit-transform: rotate(-180deg);
                    -moz-transform: rotate(-180deg);
                    -ms-transform: rotate(-180deg);
                    -o-transform: rotate(-180deg);
                }
            }
        }
    }
}
.hierarchy-chart{
    display: none;
    .hierarchy-image{
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 910px;
        margin-bottom: 60px;
        @include media-breakpoint-down(lg){
            background-size: 100%;
        }
        @include media-breakpoint-only(md){
            height: 380px;
        }
        @include media-breakpoint-down(sm){
            height: 250px;
        }
    }
}