.btn {
    &.btn-primary{
        background-color: #4e5256;
        border: 1px solid #4e5256;
        transition: all .3s ease;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        &:hover{
            background-color: $link-color;
            border: 1px solid $link-color;
        }
    }
    &.btn-back {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        padding: 15px 30px;
        svg {
            margin-right: 10px;
            transition: all .3s linear;
            -webkit-transition: all .3s linear;
            -moz-transition: all .3s linear;
            -ms-transition: all .3s linear;
            -o-transition: all .3s linear;
        }
        &:hover {
            svg {
                transform: translatex(-5px);
                -webkit-transform: translatex(-5px);
                -moz-transform: translatex(-5px);
                -ms-transform: translatex(-5px);
                -o-transform: translatex(-5px);
            }
        }
    }
    &.btn-next {        
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 15px 30px;
        svg {
            margin-left: 10px;
            transition: all .3s linear;
            -webkit-transition: all .3s linear;
            -moz-transition: all .3s linear;
            -ms-transition: all .3s linear;
            -o-transition: all .3s linear;
        }
        &:hover {
            svg {
                transform: translatex(5px);
                -webkit-transform: translatex(5px);
                -moz-transform: translatex(5px);
                -ms-transform: translatex(5px);
                -o-transform: translatex(5px);
            }
        }
    }
    &.btn-large{
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-transform: capitalize;
        letter-spacing: normal;
        padding: 20px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        @include media-breakpoint-down (sm) {
            font-size: 14px;
        }
    }
    &.btn-download{
        svg{
            margin-left: 10px;
        }
        path{
            &.arrow{
                transition: all .3s linear;
                -webkit-transition: all .3s linear;
                -moz-transition: all .3s linear;
                -ms-transition: all .3s linear;
                -o-transition: all .3s linear;
            }
        }
        &.dark{
            background-color: #4e5256;
            color: $white;
            &:hover{
                path{
                    stroke: $white;
                    &.arrow{
                        transform: translateY(5px);
                        -webkit-transform: translateY(5px);
                        -moz-transform: translateY(5px);
                        -ms-transform: translateY(5px);
                        -o-transform: translateY(5px);
                    }
                }
            }
        }
        &.white{
            background-color: $white;
            color: #4e5256;
            &:hover{
                color: $link-color;
                path{
                    stroke: $link-color;
                    &.arrow{
                        transform: translateY(5px);
                        -webkit-transform: translateY(5px);
                        -moz-transform: translateY(5px);
                        -ms-transform: translateY(5px);
                        -o-transform: translateY(5px);
                    }
                }
            }
        }
    }
    &.btn-white-outline {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #4e5256;
        border: 1px solid #4e5256;
        outline: none;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 20px 30px;
        margin: 80px auto;
        display: flex;
        align-items: center;
        &:hover{
            color: $link-color;
            border: 1px solid $link-color;
            path {
                fill: $link-color;
            }
        }
    }
    &.btn-block{
        display: block;
    }
    &:active {
        outline: none !important;
        box-shadow: none;
    }
    &:focus {
        outline: 0 !important;
        box-shadow: none;
    }
}