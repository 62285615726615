.news-articles {
    padding-top: 20px !important;
    .nav.custom-nav-tabs {
        column-gap: 30px;
        align-items: center;
        margin-bottom: 40px;
        @include media-breakpoint-only(md){
            margin-bottom: 30px;
        }
        @include media-breakpoint-down(sm){
            column-gap: 15px;
            margin-bottom: 15px;
        }
        li {
            &>span {
                font-size: 32px;
                color: rgba(0, 0, 0, 0.2);
            }
            button.custom-tab {
                border: none;
                outline: none;
                font-style: normal;
                font-weight: 600;
                font-size: 25px;
                line-height: 44px;
                background-color: transparent;
                padding: 0px;
                color: rgba(0, 0, 0, 0.2);
                transition: all .3s ease;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -ms-transition: all .3s ease;
                -o-transition: all .3s ease;
                .main-title
                {
                    display: none;
                }
                &.active {
                    color: $hitam;
                }
                &:not(.active):hover {
                    color: $hitam;
                }
                @include media-breakpoint-only(md){
                    font-size: 22px;
                }
                @include media-breakpoint-down(sm){
                    font-size: 18px;
                }
            }
        }
    }
    .list-news-articles {
        .row {
            margin: 0px;
            margin-bottom: 50px;
            .image-container {
                padding-left: 0px;
                .section-image {
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 100%;
                    width: 100%;
                    border-radius: 20px;
                    @include media-breakpoint-down(sm){
                        border-radius: 10px;
                        -webkit-border-radius: 10px;
                        -moz-border-radius: 10px;
                        -ms-border-radius: 10px;
                        -o-border-radius: 10px;
                    }
                }
            }
            .section-text {
                width: 85%;
                margin-right: auto;
                @include media-breakpoint-down(sm){
                    width: 100%;
                }
                .timestamp {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    column-gap: 10px;
                    color: $hitam;
                    list-style: none;
                    padding: 0px;
                    @include media-breakpoint-down(sm){
                        margin-bottom: 0px;
                    }
                    .date {
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 16px;
                        @include media-breakpoint-down(sm){
                            font-size: 10px;
                            margin-bottom: 0px;
                        }
                    }
                    .category {
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 16px;
                        letter-spacing: 0.2em;
                        text-transform: uppercase;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        list-style: none;
                        padding: 0px;
                        @include media-breakpoint-down(sm){
                            li{
                                font-size: 10px;
                                margin-bottom: 0px;
                            }
                        }
                        li:not(:last-child):after {
                            content: ",";
                        }
                    }
                }
                .title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 30px;
                    margin-bottom: 10px;
                    color: $hitam;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-align: left;
                    text-decoration: none;
                    transition: all .3s ease;
                    -webkit-transition: all .3s ease;
                    -moz-transition: all .3s ease;
                    -ms-transition: all .3s ease;
                    -o-transition: all .3s ease;
                    @include media-breakpoint-down(sm){
                        font-size: 12px;
                        line-height: 20px;
                        margin-bottom: 0px;
                    }
                    &:hover{
                        color: $link-color;
                    }
                }
                .desc {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 26px;
                    color: $hitam;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-align: left;
                    @media (max-width: 520px){
                        display: none;
                    }
                    @media (min-width: 520px) and (max-width: 768px){
                        font-size: 10px;
                    }
                }
                .link {
                    color: #4e5256;
                    text-align: left;
                    transition: all .3s ease;
                    -webkit-transition: all .3s ease;
                    -moz-transition: all .3s ease;
                    -ms-transition: all .3s ease;
                    -o-transition: all .3s ease;
                    i {
                        margin-left: 5px;
                    }
                    @include media-breakpoint-down(sm){
                        font-size: 10px;
                        margin-bottom: 0px;
                    }
                    &:hover{
                        color: $link-color;
                    }
                }
            }
        }
    }
    .link.load-more{
        display: table;
        margin: auto;
        outline: none;
        border: none;
        background-color: transparent;
    }
    .filter {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .form-label {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #979797;
            margin-right: 15px;
            margin-bottom: 0px;
        }
        .form-select {
            background-image: url("../images/icons/select-arrow.svg");
            background-repeat: no-repeat;
            background-position-x: calc(100% - 20px);
            background-position-y: 50%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 1px solid rgba(55, 61, 88, 0.3);
            box-sizing: border-box;
            border-radius: 5px;
            padding: 20px 80px 20px 30px;
            flex: 1;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-transform: capitalize;
            color: $hitam;
            opacity: 0.6;
            outline: none;
            width: 80%;
        }
        .select2-container{
            width: 250px !important;
            height: 60px;
            .select2-selection{
                padding: 0px 20px;
                height: 100%;
                align-items: center;
                justify-content: flex-start;
                margin: auto;
                width: 100%;
                display: flex;
                font-weight: 500;
                letter-spacing: 0.1em;
                text-transform: capitalize;
                opacity: .6;
            }
            .select2-selection__arrow{
                height: 100% !important;
                right: 10px;
            }
        }
    }
}