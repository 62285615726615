.project-lists {
    padding-bottom: 80px;
    .tab-content {
        .row {
            row-gap: 30px;
            @include media-breakpoint-down(md){
                row-gap: 40px;
            }
            .section-content {
                height: 420px;
                border-radius: 20px;
                padding: 30px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-direction: column;
                position: relative;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                &::before {
                    position: absolute;
                    content: '';
                    background: linear-gradient(360deg, rgb(0, 0, 0), rgba(0, 0, 0, 0) 36.9%);
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    bottom: 0px;
                    right: 0px;
                    border-radius: 20px;
                }
                .company-name {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: $white;
                    z-index: 2;
                }
                .section-tag {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 2;
                    flex-wrap: wrap;
                    .tag {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 22px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        color: $white;
                        margin: 0px 5px;
                        &::before {
                            content: "#";
                        }
                    }
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .link {
            display: table;
            margin: 0px auto 80px auto;
        }
    }
    .filter-and-tab {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 60px 0px;
        padding: 0px 20px;
        @include media-breakpoint-down(md){
            flex-direction: column;
            row-gap: 40px;
        }
        @include media-breakpoint-down(sm){
            margin: 30px 0px;
            padding: 0px;
        }
        .nav.custom-nav-tabs {
            column-gap: 30px;
            align-items: center;
            @include media-breakpoint-only(md){
                column-gap: 20px;
            }
            @include media-breakpoint-down(sm){
                column-gap: 10px;
            }
            li {
                &>span {
                    font-size: 32px;
                    color: rgba(0, 0, 0, 0.2);
                }
                button.custom-tab {
                    border: none;
                    outline: none;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 25px;
                    line-height: 44px;
                    background-color: transparent;
                    padding: 0px;
                    color: rgba(0, 0, 0, 0.2);
                    transition: all .3s ease;
                    -webkit-transition: all .3s ease;
                    -moz-transition: all .3s ease;
                    -ms-transition: all .3s ease;
                    -o-transition: all .3s ease;
                    svg path
                    {
                        transition: all .3s ease;
                        -webkit-transition: all .3s ease;
                        -moz-transition: all .3s ease;
                        -ms-transition: all .3s ease;
                        -o-transition: all .3s ease;
                        fill: #949494;
                    }
                    .main-title {
                        display: none;
                    }
                    &.active {
                        color: $hitam;
                        svg path {
                            fill: $hitam;
                        }
                    }
                    &:not(.active):hover{
                        color: $hitam;
                        svg path {
                            fill: $hitam;
                        }
                    }
                    @include media-breakpoint-down(sm){
                        font-size: 16px;
                        svg {
                            height: 18px;
                        }
                    }
                }
            }
        }
        .filter {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .form-label {
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 28px;
                text-align: center;
                letter-spacing: 0.2em;
                text-transform: uppercase;
                color: #979797;
                margin-right: 15px;
                margin-bottom: 0px;
                @include media-breakpoint-down(sm){
                    font-size: 12px;
                }
            }
            .form-select {
                background-image: url("../images/icons/select-arrow.svg");
                background-repeat: no-repeat;
                background-position-x: calc(100% - 20px);
                background-position-y: 50%;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border: 1px solid rgba(55, 61, 88, 0.3);
                box-sizing: border-box;
                border-radius: 5px;
                padding: 20px 30px;
                padding-right: 60px;
                flex: 1;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.1em;
                text-transform: capitalize;
                color: $hitam;
                opacity: 0.6;
                outline: none;
            }
            .select2-container{
                width: 250px !important;
                height: 60px;
                .select2-selection{
                    padding: 0px 20px;
                    height: 100%;
                    align-items: center;
                    justify-content: flex-start;
                    margin: auto;
                    width: 100%;
                    display: flex;
                    font-weight: 500;
                    letter-spacing: 0.1em;
                    text-transform: capitalize;
                    opacity: .6;
                }
                .select2-selection__arrow{
                    height: 100% !important;
                    right: 10px;
                }
            }
        }
    }
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #ddd !important;
    color: $hitam;
    font-weight: 500;
}
.project-references-modal {
    position: relative;
    .modal-content {
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
    }
    .modal-dialog {
        max-width: 80%;
        max-height: 550px;
        margin: auto;
        @include media-breakpoint-down(md){
            max-width: 90%;
            display: flex;
            align-items: flex-start !important;
        }
    }
    .modal-header {
        position: absolute;
        right: 20px;
        top: 20px;
        border: none;
        z-index: 10;
        padding: 0px;
        @include media-breakpoint-down(md){
            right: 30px;
            top: 30px;
        }
    }
    .modal-body {
        padding: 20px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        @include media-breakpoint-down(sm){
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
        }
        .project-image{
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            height: 442px;
            max-width: 442px;
            margin-left: 0;
            @include media-breakpoint-down(md)
            {
                border-radius: 10px;
                margin: auto;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -ms-border-radius: 10px;
                -o-border-radius: 10px;
            }
        }
        iframe {
            border-radius: 20px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            @include media-breakpoint-down(md)
            {
                border-radius: 10px;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -ms-border-radius: 10px;
                -o-border-radius: 10px;
            }
        }
        .project-details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            position: relative;
            .details {
                margin-top: 40px;
                @include media-breakpoint-up(lg){
                    max-height: 310px;
                    overflow-x: hidden;
                }
                @include media-breakpoint-down(md){
                    margin-top: 80px;
                }
                .company-name {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 25px;
                    line-height: 35px;
                    display: flex;
                    align-items: center;
                    text-align: left;
                    margin-bottom: 10px;
                    @include media-breakpoint-down(md){
                        margin: 10px 0px;
                    }
                }
                .section-tag {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    z-index: 2;
                    margin-bottom: 15px;
                    flex-wrap: wrap;
                    .tag {
                        font-size: 15px;
                        line-height: 22px;
                        display: flex;
                        align-items: center;
                        margin: 0px 5px;
                        &::before {
                            content: "#";
                        }
                    }
                }
                .project-descriptions {
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
        .navigation-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 30px;
            @include media-breakpoint-down(md){
                position: absolute;
                top: 0px;
                width: 100%;
                justify-content: center;
                padding-top: 15px;
            }
            .controls-button {
                padding: 10px;
                background: #FFFFFF;
                box-shadow: 0px 3px 8px 1.5px rgba(0, 0, 0, 0.1);
                border-radius: 3px;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                -ms-border-radius: 3px;
                -o-border-radius: 3px;
                padding: 5px 10px;
                cursor: pointer;
                transition: all .3s ease;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -ms-transition: all .3s ease;
                -o-transition: all .3s ease;
                &:hover{
                    background-color: rgb(252, 232, 233);
                    box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.1);
                }
                @include media-breakpoint-only(md){
                    position: absolute;
                    top: -50px;
                    &.prev{
                        left: 25%;
                    }
                    &.next{
                        right: 25%;
                    }
                }
                @include media-breakpoint-down(sm){
                    position: absolute;
                    top: -50px;
                    &.prev{
                        left: 10%;
                    }
                    &.next{
                        right: 10%;
                    }
                }
            }
            .slider-navigation {
                display: flex;
                align-items: center;
                column-gap: 15px;
                nav {
                    .nav-images {
                        width: 70px;
                        height: 70px;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        border-radius: 5px;
                        cursor: pointer;
                        @include media-breakpoint-down(md){
                            width: 45px;
                            height: 45px;
                        }
                    }
                    &.tns-nav-active {
                        border-bottom: 7px solid #ED0E15;
                    }
                }
            }
        }
    }
}