html {
    margin: 0px !important;
}
@media (prefers-reduced-motion: no-preference) {
     :root {
        scroll-behavior: smooth;
    }
}

main>section:not(.home-slider) {
    padding: 80px 0px;
    @include media-breakpoint-down(md) {
        padding: 40px 0px;
    }
    &.title-container{
        padding-top: 100px;
    }
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid $red;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: auto;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/*Error 404 and Thank You*/

.page-info {
    .page-details {
        margin: 100px 0px;
        .row {
            .content {
                margin: auto;
                width: 75%;
                text-align: center;
                & > svg {
                    width: 100%;
                }
                .title {
                    margin-top: 30px;
                }
                .descriptions {
                    font-size: 16px;
                    line-height: 26px;
                    width: 85%;
                    margin: auto;
                    margin-bottom: 60px;
                }
                .error-icons {
                    width: 70%;
                    max-width: 400px;
                }
            }
        }
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: rotateX(-100deg);
        transform-origin: top;
      }
    
      100% {
        opacity: 1;
        transform: rotateX(0deg);
        transform-origin: top;
      }
}
@keyframes slideUp {
    0% {
        opacity: 0;
        transform: rotateX(-100deg);
        transform-origin: top;
      }
    
      100% {
        opacity: 1;
        transform: rotateX(0deg);
        transform-origin: top;
      }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: rotateY(100deg);
        -webkit-transform: rotateY(100deg);
        -moz-transform: rotateY(100deg);
        -ms-transform: rotateY(100deg);
        -o-transform: rotateY(100deg);
        transform-origin: left;
    }
    
      100% {
        opacity: 1;
        transform: rotateY(0);
        transform-origin: left;
      }
}

@keyframes slideRight {
    0% {
      opacity: 0;
      transform: rotateY(-100deg);
      transform-origin: right;
    }
  
    100% {
      opacity: 1;
      transform: rotateY(0);
      transform-origin: right;
    }
}