@media (prefers-reduced-motion: reduce) {
    .opening {
        transition-property: height, visibility;
        transition-duration: .3s;
    }
}

.opening {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: all .6s linear;
    -webkit-transition: all .6s linear;
    -moz-transition: all .6s linear;
    -ms-transition: all .6s linear;
    -o-transition: all .6s linear;
}