.card-list {
    row-gap: 50px;
    margin-bottom: 60px;
    position: relative;
    z-index: 1;
    .card {
        height: 340px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 20px;
        border: none;
        overflow: hidden;
        @include media-breakpoint-only(lg){
            height: 250px;
        }
        @include media-breakpoint-only(md){
            height: 275px;
        }
        @include media-breakpoint-down(sm){
            height: 275px;
        }
        .card-info {
            text-align: center;
            position: relative;
            justify-content: flex-end;
            height: 100%;
            &:not(.card-non-hover){
                cursor: pointer;
            }
            &::before {
                position: absolute;
                content: '';
                background: linear-gradient(360deg, rgb(0, 0, 0), rgba(0, 0, 0, 0) 36.9%);
                height: 100%;
                width: 100%;
                z-index: 1;
                bottom: 0px;
                right: 0px;
                border-radius: 20px;
            }
            &:not(.card-non-hover){
                &::after {
                    position: absolute;
                    content: '';
                    background: rgba(0, 0, 0, 0.8);
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    bottom: -100%;
                    right: 0px;
                    border-radius: 20px;
                    -webkit-transition: all 0.3s ease-in-out 0s;
                    -moz-transition: all 0.3s ease-in-out 0s;
                    transition: all 0.3s ease-in-out 0s;
                }
            }
        }
        &:hover {
            .card-info:not(.card-non-hover){
                .content {
                    bottom: 35%;
                    .card-link {
                        display: flex;
                    }
                    @include media-breakpoint-down(xs) {
                        bottom: 25%;
                    }
                }
            }
            .card-info:not(.card-non-hover)::before {
                display: none;
            }
            .card-info:not(.card-non-hover)::after {
                bottom: 0px;
            }
        }
    }
    .items-slide{
        .card-white{
            background-color: #FFF;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
            height: 416px;
            border-radius: 20px;
            border: none;
            overflow: hidden;
            position: relative;
            .card-contents{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                height: 100%;
                &::after {
                    position: absolute;
                    content: '';
                    background: rgba(0, 0, 0, 0.8);
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    bottom: -100%;
                    right: 0px;
                    border-radius: 20px;
                    -webkit-transition: all 0.3s ease-in-out 0s;
                    -moz-transition: all 0.3s ease-in-out 0s;
                    transition: all 0.3s ease-in-out 0s;
                }
                .image {
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 75%;
                    @include media-breakpoint-down(sm) {
                        width: 80%;
                    }
                }
                .content{
                    height: 25%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .card-title{
                        font-size: 20px;
                        color: #000;
                        text-align: center;
                        font-weight: 300;
                        margin-bottom: 0px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }
        }
        &:hover {
            .card-contents{
                .content {
                    bottom: 35%;
                    .card-link {
                        display: flex;
                    }
                    .card-title{
                        color: #FFF;
                    }
                }
            }
            .card-contents::after {
                bottom: 0px;
            }
        }
    }
    .content {
        position: absolute;
        bottom: 0px;
        padding: 20px;
        width: 100%;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        .card-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: $white;
            z-index: 10;
            position: relative;
        }
        .card-link {
            font-weight: bold;
            font-size: 14px;
            line-height: 38px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: $white;
            display: none;
            justify-content: center;
            align-items: center;
            z-index: 10;
            position: relative;
            width: max-content;
            margin: auto;
            i {
                font-size: 10px;
                margin-left: 10px;
                transition: all .3s linear;
                -webkit-transition: all .3s linear;
                -moz-transition: all .3s linear;
                -ms-transition: all .3s linear;
                -o-transition: all .3s linear;
            }
            &:hover {
                i {
                    transform: translatex(5px);
                    -webkit-transform: translatex(5px);
                    -moz-transform: translatex(5px);
                    -ms-transform: translatex(5px);
                    -o-transform: translatex(5px);
                }
            }
        }
    }
}