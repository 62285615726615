.contact-us-forms {
    background-color: #E5E5E5;
    padding: 0px !important;
    overflow: hidden;
    .company-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;

        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }
    .contact-form {
        padding: 80px;
        @include media-breakpoint-down(lg){
            padding: 60px;
        }
        @include media-breakpoint-only(md){
            padding: 60px 30px;
        }
        @include media-breakpoint-down(sm){
            padding: 60px 15px;
        }
        .submit {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 30px;
            .g-recaptcha {
                margin-right: 20px;
            }
            .btn-submit {
                height: 67px;
                padding: 25px 40px;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                @include media-breakpoint-down(xs){
                    display: block;
                    width: 100%;
                }
            }
        }
        .form-control {
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
            &+label {
                font-weight: 600;
            }
        }
    }
}