.home-news {
    overflow: hidden;
    .row {
        justify-content: space-between;
        margin: 0px;
        .nav.custom-nav-tabs {
            column-gap: 30px;
            align-items: center;
            margin-bottom: 30px;
            @include media-breakpoint-down(sm) {
                justify-content: center;
            }
            li {
                &>span {
                    font-size: 32px;
                    color: rgba(0, 0, 0, 0.2);
                }
                button.custom-tab {
                    border: none;
                    outline: none;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 44px;
                    background-color: transparent;
                    padding: 0px;
                    color: rgba(0, 0, 0, 0.2);
                    transition: all .3s ease;
                    -webkit-transition: all .3s ease;
                    -moz-transition: all .3s ease;
                    -ms-transition: all .3s ease;
                    -o-transition: all .3s ease;
                    &:hover{
                        color: $hitam;
                    }
                    .main-title{
                        display: none;
                    }
                    &.active {
                        color: $hitam;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 18px;
                        text-align: center;
                        display: block;
                    }
                    @include media-breakpoint-only(md) {
                        font-size: 24px;
                        text-align: center;
                        display: block;
                    }
                }
            }
        }
        .tab-content .tab-pane {
            .items {
                margin-bottom: 30px;
                .date-category {
                    margin-bottom: 10px;
                    .date {
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 16px;
                    }
                    .category {
                        text-transform: uppercase;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 16px;
                        letter-spacing: 3px;
                    }
                    .divider {
                        margin: 0px 10px;
                    }
                }
                .title {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    text-decoration: none;
                    color: #25293A;
                    transition: color .3s ease;
                    -webkit-transition: color .3s ease;
                    -moz-transition: color .3s ease;
                    -ms-transition: color .3s ease;
                    -o-transition: color .3s ease;
                    &:hover{
                        color: $link-color;
                    }
                }
            }
            a.link {
                margin-top: 20px;
                @include media-breakpoint-down(lg){
                    margin-bottom: 3rem;
                }
            }
        }
        .embed-content {
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-sizing: border-box;
            padding: 0px;
            .nav.custom-embed-nav-tabs {
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                justify-content: space-between;
                li {
                    button.custom-tab {
                        border: none;
                        outline: none;
                        font-weight: 600;
                        font-size: 25px;
                        line-height: 44px;
                        background-color: transparent;
                        padding: 20px 30px;
                        color: rgba(0, 0, 0, 0.2);
                        @include media-breakpoint-down(sm){
                            padding: 0px 10px;
                        }
                        .main-title {
                            display: none;
                            @include media-breakpoint-down(sm){
                                font-size: 16px;
                            }
                        }
                        &.active {
                            border-top: 3px solid $red;
                            color: $hitam;
                            position: relative;
                            top: -2px;
                            width: 100%;
                            border-left: 1px solid rgba(0, 0, 0, 0.1);
                            border-right: 1px solid rgba(0, 0, 0, 0.1);
                            .icon {
                                display: none;
                            }
                            .main-title {
                                display: block;
                            }
                        }
                    }
                    &.divider {
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        height: 100%;
                    }
                    &.active {
                        flex: 1;
                    }
                }
            }
            .tab-content {
                .tab-pane {
                    &#youtube {
                        padding-bottom: 30px;
                        .link {
                            padding: 0px;
                            margin: 0px 20px;
                            @include media-breakpoint-down(md){
                                text-align: left;
                            }
                        }
                    }
                    .youtube-embed {
                        iframe {
                            width: 100%;
                            height: 250px;
                        }
                        .title {
                            padding: 30px 20px 0px 20px;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 35px;
                            text-decoration: none;
                            @include media-breakpoint-down(md){
                                text-align: left;
                            }
                        }
                    }
                    .instagram-container {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-gap: 10px;
                        .instagram-photo {
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                            height: 140px;
                        }
                    }
                }
            }
        }
    }
}