.careers {
    padding-bottom: 0px !important;
    .section-title {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        text-align: center;
        position: relative;
        &:not(.section-title-reason) {
            box-shadow: inset 0 0 0 300px rgba(0, 0, 0, 0.6);
        }
    }
    .section-points {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 60px;
        row-gap: 40px;
        padding-bottom: 60px;
        flex-wrap: wrap;
        .items {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .icon{
                height: 120px;
                width: auto;
            }
            .image-desc {
                margin-top: 20px;
                font-weight: bold;
                font-size: 16px;
                line-height: 26px;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
            }
        }
    }
    .vacancies {
        background-color: #E5E5E5;
        margin-top: 100px;
        .row {
            margin: 0px;
            .text-section {
                padding: 80px 20px;
                .descriptions {
                    width: 80%;
                    margin-right: auto;
                    margin-bottom: 30px;
                }
                .btn-vacancies {
                    padding: 20px 30px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    svg {
                        margin-left: 15px;
                    }
                }
            }
            .section-image {
                position: relative;
                background-size: cover;
                background-position: left;
                background-repeat: no-repeat;
                @include media-breakpoint-down(sm){
                    height: 180px;
                }
                .image {
                    width: 578px;
                    position: absolute;
                    right: 10%;
                    bottom: 0px;
                    z-index: 10;
                    @include media-breakpoint-down(sm){
                        width: 350px;
                    }
                }
            }
        }
        .section-text{
            overflow-y: clip;
            position: relative;
            .triangle-topright {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 0;
                height: 0;
                border-top: 380px solid #E5E5E5;
                border-left: 180px solid transparent;
                transform: translateX(-175px);
                -webkit-transform: translateX(-175px);
                -moz-transform: translateX(-175px);
                -ms-transform: translateX(-175px);
                -o-transform: translateX(-175px);
                @include media-breakpoint-down(sm){
                    display: none;
                }
            }
        }
    }
}