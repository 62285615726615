.services-commitment {
    .section-title {
        text-align: center;
        padding-bottom: 60px;
        padding-top: 0px;
        h2.title {
            margin-bottom: 0;
        }
        @include media-breakpoint-down(md){
            padding-bottom: 30px;
        }
        .descriptions {
            width: 80%;
            margin: auto;
        }
    }
    .section-points {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 60px;
        flex-wrap: wrap;
        @include media-breakpoint-down(md){
            row-gap: 40px;
        }
        .items {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .icon{
                height: 120px;
                width: auto;
            }
            .image-desc {
                margin-top: 20px;
                font-weight: bold;
                font-size: 16px;
                line-height: 26px;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
            }
        }
    }
}