.contact-us-titles {
    position: relative;
    @include media-breakpoint-only(lg){
        &.title-container{
            padding-top: 80px;
        }
    }
    @include media-breakpoint-only(md){
        &.title-container{
            padding: 80px !important;
        }
    }
    @include media-breakpoint-down(sm){
        &.title-container{
            padding: 60px 0px !important;
        }
    }
    .text-container {
        padding-bottom: 0px;
        padding-top: 80px;
        @include media-breakpoint-down(lg) {
            padding-top: 40px;
        }
        .title {
            margin-bottom: 15px;
        }
    }
    &::before {
        position: absolute;
        content: '';
        background: $soft-blue;
        height: 550px;
        width: 100%;
        z-index: -1;
        top: 0px;
        left: 0px;
        @include media-breakpoint-down(sm) {
            height: 600px;
        }
    }
}