.controls-slider-next {
    position: absolute;
    display: block;
    bottom: 40%;
    color: #fff;
    right: 1%;
    z-index: 10;
    padding: 15px 20px;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background-color: #FFF;
    cursor: pointer;
    outline: none;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    @include media-breakpoint-down(lg){
        padding: 5px 10px;
        bottom: -3.5rem;
        right: 20%;
        z-index: 100;
        svg{
            height: 20px !important;
        }
    }
    &:hover{
        background-color: rgb(252, 232, 233);
        box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.1);
    }
}
.controls-slider-prev {
    position: absolute;
    display: block;
    bottom: 40%;
    color: #fff;
    left: 1%;
    z-index: 10;
    padding: 15px 20px;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background-color: #FFF;
    cursor: pointer;
    outline: none;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    @include media-breakpoint-down(lg){
        padding: 5px 10px;
        bottom: -3.5rem;
        left: 20%;
        z-index: 100;
        svg{
            height: 20px;
        }
    }
    &:hover{
        background-color: rgb(252, 232, 233);
        box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.1);
    }
}

.tns-nav {
    width: 100%;
    position: absolute;
    z-index: 10;
    bottom: -3rem;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    button[aria-controls] {
        position: relative;
        width: auto;
        height: 15px;
        border-radius: 100%;
        border: 2px solid $white;
        box-shadow: 0 0 0 1px $hitam;
        background-color: transparent;
        margin: 0 0.25rem;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        outline: none;
        &.tns-nav-active {
            background-color: $red;
        }
    }
}