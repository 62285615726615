.family-group {
    overflow: hidden;
    .section-title {
        padding-bottom: 60px;
        height: 500px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        max-width: 100vw;
        background-position: right 50% bottom 75%;
        @include media-breakpoint-only(sm){
            height: 400px;
        }
        @include media-breakpoint-down(xs){
            height: 300px;
        }
        .title {
            margin: 0px;
        }
        .sub-title {
            color: rgba(255, 255, 255, 0.6);
        }
        &::before {
            position: absolute;
            content: '';
            background: linear-gradient(360deg, rgb(0, 0, 0), rgba(0, 0, 0, 0) 36.9%);
            height: 100%;
            width: 100%;
            z-index: 1;
            bottom: 0px;
            right: 0px;
        }
    }
    .teams {
        margin-top: 80px;
        position: relative;
        @include media-breakpoint-down(lg){
            .container{
                padding: 0px;
                max-width: 100%;
                .tns-nav{
                    bottom: 7rem;
                }
                .controls-slider-prev{
                    bottom: 6.5rem;
                    left: 15%;
                }
                .controls-slider-next{
                    bottom: 6.5rem;
                    right: 15%;
                }
            }
        }
        @include media-breakpoint-down(xs){
            .container{
                .controls-slider-prev{
                    left: 5%;
                }
                .controls-slider-next{
                    right: 5%;
                }
            }
        }
        .row.teams {
            width: 83.3%;
            margin: auto;
            row-gap: 30px;
            justify-content: center;
            @include media-breakpoint-down(lg){
                display: block;
                padding-bottom: 60px;
            }
            .items {
                height: 350px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 15px;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-direction: column;
                position: relative;
                cursor: pointer;
                transition: all .3s ease;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -ms-transition: all .3s ease;
                -o-transition: all .3s ease;
                @include media-breakpoint-down(xs){
                    height: 300px;
                }
                .name{
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 30px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: $white;
                    z-index: 5;
                    margin-bottom: 0px;
                }
                .position {
                    font-size: 16px;
                    line-height: 30px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: $white;
                    z-index: 5;
                    margin-bottom: 0px;
                }
                &::before {
                    position: absolute;
                    content: '';
                    background: linear-gradient(360deg, rgb(0, 0, 0), rgba(0, 0, 0, 0) 36.9%);
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    bottom: 0px;
                    right: 0px;
                    border-radius: 15px;
                }
                &:hover{
                    box-shadow: -1px 2px 14px 3px rgba(0,0,0,0.21);
                    -webkit-box-shadow: -1px 2px 14px 3px rgba(0,0,0,0.21);
                    -moz-box-shadow: -1px 2px 14px 3px rgba(0,0,0,0.21);
                    transform: scale(1.03);
                    -webkit-transform: scale(1.03);
                    -moz-transform: scale(1.03);
                    -ms-transform: scale(1.03);
                    -o-transform: scale(1.03);
                }
            }
        }
    }
    .structures{
        background-image: url('../images/backgrounds/structures_background.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    #organization-structures {
        .modal-body {
            .organization-structures {
                height: 80vh;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
    .details-profile {
        .modal-body {
            padding: 50px;
            @include media-breakpoint-down(lg){
                padding: 30px;
                .row{
                    row-gap: 30px;
                }
            }
            .photo-profile {
                height: 350px;
                width: auto;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 15px;
            }
            .profile-details {
                padding-left: 30px;
                @include media-breakpoint-down(md){
                    padding: 0px;
                }
                @include media-breakpoint-down(sm){
                    .name{
                        margin-bottom: 0px;
                    }
                }
                .position {
                    font-weight: 500;
                    font-size: 25px;
                    line-height: 44px;
                    @include media-breakpoint-down(sm){
                        font-size: 16px;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
    .modal-dialog {
        max-width: 75%;
        height: 550px;
        margin: auto;
        @include media-breakpoint-down(lg){
            max-width: 90%;
        }
    }
    .modal-header {
        position: absolute;
        right: 20px;
        top: 20px;
        border: none;
        z-index: 10;
        @include media-breakpoint-down(md){
            right: 0px;
            top: 0px;
            button svg {
                width: 60%;
            }
        }
    }
}