.details-news {
    margin-top: 60px;
    @include media-breakpoint-down(lg){
        margin-top: 30px;
    }
    .section-image {
        width: 100%;
        height: 400px;
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;
        border-radius: 20px;
        @include media-breakpoint-down(md){
            height: 350px;
        }
        @include media-breakpoint-down(sm){
            height: 200px;
        }
    }
    .text-caption {
        display: flex;
        margin-top: 15px;
        margin-bottom: 15px;
        &.has-image {
            margin-top: 40px;
            @include media-breakpoint-down(sm) {
                margin-top: 30px;
            }
        }
        .timestamp {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 10px;
            color: $hitam;
            list-style: none;
            padding: 0px;
            @include media-breakpoint-down(sm){
                margin-bottom: 0px;
            }
            .date {
                font-weight: 300;
                font-size: 16px;
                line-height: 16px;
                @include media-breakpoint-down(sm){
                    font-size: 10px;
                    margin-bottom: 0px;
                }
            }
            .category {
                font-weight: 300;
                font-size: 16px;
                line-height: 16px;
                letter-spacing: 0.2em;
                text-transform: uppercase;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                list-style: none;
                padding: 0px;
                @include media-breakpoint-down(sm){
                    li{
                        font-size: 10px;
                        margin-bottom: 0px;
                    }
                }
                li:not(:last-child):after {
                    content: ",";
                }
            }
        }
    }
    .content-news {
        .text-h1,
        .text-h2,
        .text-h3 {
            color: $hitam;
        }
        .text-h4,
        .text-h5 {
            color: $hitam;
            font-weight: bold;
        }
        .text-h1 {
            font-weight: bold;
            font-size: 40px;
            line-height: 45px;
            @include media-breakpoint-down(sm) {
                font-size: 30px;
                line-height: 1.1;
            }
            @include media-breakpoint-only(md) {
                font-size: 35px;
                line-height: 1.1;
            }
        }
        .text-h2 {
            font-weight: bold;
            font-size: 35px;
            line-height: 45px;
            @include media-breakpoint-down(sm) {
                font-size: 20px;
                line-height: 1.1;
            }
            @include media-breakpoint-only(md) {
                font-size: 25px;
                line-height: 1.1;
            }
        }
        .text-h3 {
            font-weight: 600;
            font-size: 30px;
            line-height: 44px;
            @include media-breakpoint-down(sm) {
                font-size: 20px;
                line-height: 1.1;
            }
            @include media-breakpoint-only(md) {
                font-size: 25px;
                line-height: 1.1;
            }
        }
        .text-h4 {
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 44px;
            @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 1.1;
            }
            @include media-breakpoint-only(md) {
                font-size: 20px;
                line-height: 1.1;
            }
        }
        blockquote {
            color: $hitam;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 35px;
            text-align: center;
            padding: 40px 0;
            width: 60%;
            margin: auto;
            position: relative;
            @include media-breakpoint-only(md){
                font-size: 18px;
            }
            @include media-breakpoint-down(sm){
                font-size: 14px;
                width: 90%;
            }
            span {
                display: contents;
            }
            &:before {
                content: '';
                background-image: url("data:image/svg+xml,<svg width='32' height='22' viewBox='0 0 32 22' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10.3616 21.88H0.569625L9.78563 0.0879974H16.0256L10.3616 21.88ZM25.9136 21.88H16.1216L25.3376 0.0879974H31.5776L25.9136 21.88Z' fill='black'/> </svg> ");
                position: absolute;
                color: $orange;
                font-size: 50px;
                left: -2%;
                height: 20px;
                width: 20px;
                background-size: 100%;
                background-repeat: no-repeat;
            }
            &:after {
                content: '';
                background-image: url("data:image/svg+xml,<svg width='32' height='22' viewBox='0 0 32 22' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M21.6384 0.120003H31.4304L22.2144 21.912H15.9744L21.6384 0.120003ZM6.08637 0.120003H15.8784L6.66237 21.912H0.422375L6.08637 0.120003Z' fill='black'/> </svg>");
                position: absolute;
                color: $orange;
                font-size: 50px;
                right: -2%;
                bottom: 15%;
                height: 20px;
                width: 20px;
                background-size: 100%;
                background-repeat: no-repeat;
            }
        }
        figure {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            figcaption {
                font-size: 14px;
                color: #666666;
                line-height: 1.6;
                font-style: italic;
                padding-top: 5px;
            }
            img {
                width: 100%;
            }
        }
        p{
            text-align: justify;
        }
        .details-news-slider{
            width: 60%;
            position: relative;
            padding-bottom: 60px;
            margin: 60px auto;
            @include media-breakpoint-down(sm){
                width: 80%;
            }
            .slider-content{
                border-radius: 20px;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                .image {
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 480px;
                    width: 100%;
                    border-radius: 20px;
                    @include media-breakpoint-down(md){
                        height: 380px;
                    }
                    @include media-breakpoint-down(sm){
                        height: 300px;
                    }
                }
            }
            .controls-slider-prev {
                left: -13%;
                @include media-breakpoint-down(sm){
                    bottom: -2%;
                    left: 3%;
                }
            }
            .controls-slider-next {
                right: -13%;
                @include media-breakpoint-down(sm){
                    bottom: -2%;
                    right: 3%;
                }
            }
            .tns-nav{
                bottom: 0rem;
            }
        }
    }
    .share-news {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 60px;
        .share-title {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 28px;
            letter-spacing: 0.205em;
            text-transform: uppercase;
            margin-right: 10px;
            margin-bottom: 0px;
        }
        .list-inline {
            display: flex;
            margin-bottom: 0px;
            .list-inline-item {
                .rounded {
                    background-color: #9C9FB6;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    -ms-border-radius: 5px;
                    -o-border-radius: 5px;
                    text-decoration: none;
                    color: $white;
                    transition: all .3s ease;
                    -webkit-transition: all .3s ease;
                    -moz-transition: all .3s ease;
                    -ms-transition: all .3s ease;
                    -o-transition: all .3s ease;
                    &.facebook:hover {
                        background-color: #4267B2;
                    }
                    &.twitter:hover {
                        background-color: #1DA1F2;
                    }
                    &.linkedin:hover {
                        background-color: #0077B5;
                    }
                    @include media-breakpoint-down(lg) {
                        &.facebook {
                            background-color: #4267B2;
                        }
                        &.twitter {
                            background-color: #1DA1F2;
                        }
                        &.linkedin {
                            background-color: #0077B5;
                        }
                    }
                }
            }
        }
    }
    .back {
        text-transform: uppercase;
        text-decoration: none;
        color: $black;
        font-size: 14px;
        line-height: normal;
        font-weight: 600;
        letter-spacing: .2em;
        margin-bottom: 2rem;
        display: block;
        width: max-content;
        margin-top: 10px;
        @include media-breakpoint-down (sm) {
            font-size: 12px;
            margin-bottom: 1.5rem;
        }
        transition: all .3s ease;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        &:hover {
            color: $red;
        }
    }
}