#wrapper {
    header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1000;
        max-width: 100vw;
        transition: all 0.5s ease-in;
        -webkit-transition: all 0.5s ease-in;
        -moz-transition: all 0.5s ease-in;
        -ms-transition: all 0.5s ease-in;
        -o-transition: all 0.5s ease-in;
        .navbar.scroll-up,
        .navbar.scroll-down {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 70px;
            z-index: 999;
            background: #FFF;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            transform: translateY(0px);
            -webkit-transform: translateY(0px);
            -moz-transform: translateY(0px);
            -ms-transform: translateY(0px);
            -o-transform: translateY(0px);
        }
        .navbar.scroll-down {
            transform: translateY(-100%);
            -webkit-transform: translateY(-100%);
            -moz-transform: translateY(-100%);
            -ms-transform: translateY(-100%);
            -o-transform: translateY(-100%);
        }
        .navbar {
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            position: relative;
            height: 100%;
            padding-top: 0px;
            @include media-breakpoint-down(xl){
                padding: 0rem 0.5rem 0rem 0.4rem;
            }
            .container {
                @media (min-width: 1280px) {
                    padding-left: 30px;
                    padding-right: 30px;
                }
                .navbar-brand {
                    @include media-breakpoint-down(xl) {}
                    img {
                        position: relative;
                        z-index: 2;
                        transition: all 0.3s ease-in-out;
                        -webkit-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        -ms-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;                                              
                        @include media-breakpoint-down(xl)
                        {
                            height: 50px;
                        }
                        @include media-breakpoint-down(md) {
                            height: 45px;
                        }
                        @include media-breakpoint-down(sm) {
                            height: 40px;
                        }
                        @include media-breakpoint-down(xs) {
                            height: 30px;
                        }
                    }
                }
                .navbar-responsive{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 15px 0px;
                    .language {
                        margin-bottom: 0px;
                        a{
                            font-weight: 500;
                            font-size: 18px;
                            letter-spacing: 0.2em;
                            text-transform: uppercase;
                            margin-bottom: 5px;
                            margin-bottom: 0px;
                            color: #000000;
                        }
                    }
                
                    @include media-breakpoint-up(xl){
                        display: none;
                        visibility: hidden;
                        opacity: 0;
                    }
                }
                .navbar-collapse {
                    flex-direction: column !important;
                    align-items: flex-end;
                    justify-content: center;
                    transition: all .4s ease;
                    -webkit-transition: all .4s ease;
                    -moz-transition: all .4s ease;
                    -ms-transition: all .4s ease;
                    -o-transition: all .4s ease;
                    .menu-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 60%;
                        position: relative;
                        @include media-breakpoint-down(md) {
                            width: 100%;
                            flex-direction: column-reverse;
                            row-gap: 1rem;
                            align-items: flex-start;
                        }
                        &.end {
                            justify-content: flex-end;
                        }
                    }
                    .header-top{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0px 20px;
                        width: 80%;
                        height: 54px;
                        margin-bottom: 10px;
                        position: relative;
                        top: 0px;
                        border-radius: 0px 0px 5px 5px;
                        -webkit-border-radius: 0px 0px 5px 5px;
                        -moz-border-radius: 0px 0px 5px 5px;
                        -ms-border-radius: 0px 0px 5px 5px;
                        -o-border-radius: 0px 0px 5px 5px;
                        @include media-breakpoint-down(lg){
                            width: 100%;
                            padding: 0px;
                        }
                        .section-left,
                        .section-center,
                        .section-right {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                        }
                        .section-left {
                            border-right: 1px solid rgba(255, 255, 255, 0.4);
                            .nav-link {
                                font-size: 13px;
                                line-height: 19px;
                                font-weight: 500;
                                text-transform: capitalize;
                                color: rgba(0, 0, 0, 0.6);
                                padding: 0px 20px 0px 0px;
                                transition: all .3s ease;
                                -webkit-transition: all .3s ease;
                                -moz-transition: all .3s ease;
                                -ms-transition: all .3s ease;
                                -o-transition: all .3s ease;
                                &.active {
                                    color: #ED0E15;
                                }
                                &:hover{
                                    color: #ED0E15;
                                }
                            }
                            @include media-breakpoint-down(lg){
                                display: none;
                                visibility: hidden;
                                opacity: 0;
                            }
                        }
                        .section-center {
                            flex: 1;
                            border-right: 1px solid rgba(255, 255, 255, 0.4);
                            padding: 0px 20px;
                            form {
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                                border-bottom: 1px solid rgba(51, 51, 51, 0.6) !important;
                                input {
                                    font-size: 13px;
                                    line-height: 19px;
                                    font-weight: normal;
                                    text-transform: capitalize;
                                    background-color: transparent;
                                    border: none;
                                    outline: none;
                                    color: rgba(0, 0, 0, 0.6);
                                    flex: 1;
                                }
                                button[type="submit"] {
                                    background-color: transparent;
                                    border: none;
                                    outline: none;
                                    opacity: .6;
                                    margin-bottom: 3px;
                                }
                            }
                            @include media-breakpoint-down(lg){
                                background: rgba(157, 157, 157, 0.1);
                            }
                        }
                        .section-right {
                            .nav-link {
                                font-size: 18px;
                                font-weight: normal;
                                color: rgba(0, 0, 0, 0.6);
                                padding: 0px 0px 0px 20px;
                                position: relative;
                                cursor: pointer;
                                &::after {
                                    position: absolute;
                                    content: '';
                                    -webkit-border-radius: 0.375rem;
                                    border-radius: 0.375rem;
                                    height: 0.3rem;
                                    width: 0.3rem;
                                    margin: auto;
                                    background-color: transparent;
                                    bottom: -3px;
                                    right: calc(50% - 12px);
                                }
                                &.active {
                                    opacity: 1;
                                    font-weight: 600;
                                    color: #000000;
                                    &::after {
                                        background-color: $biru;
                                    }
                                }
                                a {
                                    color: rgba(0, 0, 0, 0.6);
                                }
                            }
                            @include media-breakpoint-down(lg){
                                display: none;
                                visibility: hidden;
                                opacity: 0;
                            }
                        }
                    }
                    .current-user {
                        position: absolute;
                        cursor: pointer;
                        padding: 5px 15px;
                        border-radius: 5px;
                        right: 0;
                        top: 10px;
                        transform-origin: top;
                        background-color: transparent;
                        transition: background-color .3s ease;
                        z-index: 3;
                        display: flex;
                        flex-direction: column;
                        row-gap: 5px;
                        @include media-breakpoint-down(md) {
                            position: relative;
                            text-transform: capitalize;
                            letter-spacing: 0;
                        }
                        span {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            svg {
                                transition: transform .3s ease;
                            }

                            path {
                                fill: $white;
                                @include media-breakpoint-down(md) {
                                    fill: $hitam;
                                }
                            }
                        }

                        &.open {
                            background-color: rgba($color: #ffffff, $alpha: .5);
                            color: $hitam;

                            svg {
                                transform: scaleY(-1);
                            }

                            path {
                                fill: $hitam !important;
                            }
                        }
                    }
                    .header-user {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        display: none;

                        &-link {
                            color: $hitam;
                            &:hover {
                                color: $red;
                            }
                        }
                    }
                    .navbar-nav {
                        .nav-item-dropdown {
                            i {
                                font-size: .9rem;
                                margin-left: 5px;
                                cursor: pointer;
                            }
                        }
                        li {
                            a {
                                font-size: 13px;
                                letter-spacing: 1.4px;
                                line-height: 2;
                                color: inherit;
                                &:hover{
                                    color: $link-color;
                                    &+i{
                                        color: $link-color;
                                    }
                                }
                                @include media-breakpoint-down (sm) {
                                    font-size: 11px;
                                    letter-spacing: 0.1rem;
                                    line-height: 1.5;
                                }
                            }
                            &.nav-item:not(.language) a.nav-link {
                                position: relative;
                                display: inline-block;
                                text-transform: uppercase;
                                padding-right: 0px;
                                font-weight: 400;
                                transition: all .1s ease;
                                -webkit-transition: all .1s ease;
                                -moz-transition: all .1s ease;
                                -ms-transition: all .1s ease;
                                -o-transition: all .1s ease;
                                &.active {
                                    color: #ED0E15;
                                }
                            }
                            @media screen and (min-width: 1200px) {
                                &:not(:last-child) {
                                    margin-right: 20px;
                                }
                            }
                        }
                        li.language,
                        li.separator {
                            margin-right: 0px;
                        }
                        &.select-language {
                            flex-direction: row;
                            align-items: center;
                        }
                        @include media-breakpoint-up(xl) {
                            align-items: center;
                        }
                    }
                }

            }
            #navbarSupportedContent {
                @include media-breakpoint-down(lg) {
                    background-color: $white;
                    position: absolute;
                    width: 85%;
                    top: 0px;
                    right: 0px;
                    color: $hitam;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
                    height: 100vh;
                    max-height: 100vh;
                    overflow: scroll;
                    z-index: 100;
                    transform: translateX(100%);
                    -webkit-transform: translateX(100%);
                    -moz-transform: translateX(100%);
                    -ms-transform: translateX(100%);
                    -o-transform: translateX(100%);
                    &.opening{
                        transform: translateX(0%);
                        -webkit-transform: translateX(0%);
                        -moz-transform: translateX(0%);
                        -ms-transform: translateX(0%);
                        -o-transform: translateX(0%);
                    }
                    .close-button{
                        display: block;
                        margin-left: auto;
                    }
                }
                @include media-breakpoint-down(sm) {
                    top: 0px;
                }
                @include media-breakpoint-only(md) {
                    top: 0px;
                }
                ul.navbar-nav {
                    @include media-breakpoint-down(lg){
                        padding: 0px 30px 30px 30px;
                    }
                    li.nav-item {
                        transition: all 0.1s linear;
                        @include media-breakpoint-up(xl) {
                            height: 100%;
                            display: flex;
                            align-items: center;
                        }
                        &:hover{
                            .nav-link{
                                color: #ED0E15 !important;
                            }
                            i.fa-caret-down{
                                color: #ED0E15 !important;
                            }
                        }
                        &.active{
                            .nav-link{
                                color: #ED0E15 !important;
                            }
                            i.fa-caret-down{
                                color: #ED0E15 !important;
                            }
                        }
                        .nav-item-dropdown {
                            display: flex;
                            align-items: center;
                            i {
                                font-size: .9rem;
                                margin-left: 5px;
                                cursor: pointer;
                            }
                        }
                        &.nav-mega-menu {
                            position: relative;
                            .dropdown-items
                            {
                                border: none;
                                display: flex;
                                flex-direction: column;
                                column-gap: 10px;
                                top: 3rem;
                                left: -50%;
                                z-index: 100;
                                align-items: flex-start;
                                transition: all .3s ease-in-out;
                                -webkit-transition: all .3s ease-in-out;
                                -moz-transition: all .3s ease-in-out;
                                -ms-transition: all .3s ease-in-out;
                                -o-transition: all .3s ease-in-out;
                                max-width: 100%;
                                @include media-breakpoint-up(xl){
                                    visibility: hidden;
                                    opacity: 0;
                                    background: #FFFFFF;
                                    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                                    padding: 0px;
                                    border-radius: 0px;
                                    -webkit-border-radius: 0px;
                                    -moz-border-radius: 0px;
                                    -ms-border-radius: 0px;
                                    -o-border-radius: 0px;
                                    transform: translateY(10px);
                                    -webkit-transform: translateY(10px);
                                    -moz-transform: translateY(10px);
                                    -ms-transform: translateY(10px);
                                    -o-transform: translateY(10px);
                                    min-width: max-content;
                                }
                                @include media-breakpoint-down(lg){
                                    display: none;
                                    padding-top: 0px;
                                }
                                .list-menu{
                                    list-style: none;
                                    padding: 0px;
                                    display: block;
                                    width: 100%;
                                    .sub-nav-item {
                                        margin: 0px;
                                        padding: 8px 20px;
                                        transition: all .3s ease-in-out;
                                        -webkit-transition: all .3s ease-in-out;
                                        -moz-transition: all .3s ease-in-out;
                                        -ms-transition: all .3s ease-in-out;
                                        -o-transition: all .3s ease-in-out;
                                        color: #000000 !important;
                                        @include media-breakpoint-up(xl) {
                                            justify-content: space-between;
                                            display: flex;
                                            align-items: center;
                                        }
                                        @include media-breakpoint-down(xs) {
                                            line-height: normal;
                                        }
                                        a.sub-nav-link {
                                            font-weight: 600;
                                            font-size: 13px;
                                            letter-spacing: 0.2em;
                                            text-transform: uppercase;
                                            color: inherit;
                                            text-decoration: none;
                                            @include media-breakpoint-down (lg) {
                                                font-weight: 500;
                                            }

                                            @include media-breakpoint-down (sm) {
                                                font-size: 11px;
                                                letter-spacing: 0.1rem;
                                                line-height: 13px;
                                            }
                                        }
                                        &.has-child {
                                            i {
                                                @include media-breakpoint-up(xl) {
                                                    font-size: 13px;
                                                }
                                                color: #000000 !important;
                                                @include media-breakpoint-down(lg) {
                                                    padding-left: .8rem;
                                                    &::before{
                                                        content: "\f0d7";
                                                    }
                                                }
                                            }
                                            @include media-breakpoint-up(xl) {
                                                .sub-nav-link {
                                                    flex: 1;
                                                }
                                            }
                                        }
                                        a.sub-nav-child{
                                            font-weight: 300;
                                        }
                                        .list-sub-menu {
                                            list-style: none;
                                            z-index: 80;
                                            width: max-content;
                                            padding: 0px;
                                            height: max-content;
                                            border-radius: 0px;
                                            -webkit-border-radius: 0px;
                                            -moz-border-radius: 0px;
                                            -ms-border-radius: 0px;
                                            -o-border-radius: 0px;
                                            transition: all .3s ease-in-out;
                                            -webkit-transition: all .3s ease-in-out;
                                            -moz-transition: all .3s ease-in-out;
                                            -ms-transition: all .3s ease-in-out;
                                            -o-transition: all .3s ease-in-out;
                                            @include media-breakpoint-up(xl) {
                                                position: absolute;
                                                left: 100%;
                                                top: 0;
                                                visibility: hidden;
                                                opacity: 0;
                                                background: #E5E5E5;
                                                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
                                            }
                                            @include media-breakpoint-down(lg) {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                                &:hover {
                                    visibility: visible;
                                    opacity: 1;
                                    display: flex;
                                    transform: translateY(0);
                                }
                            }
                            @include media-breakpoint-up(xl) {
                                &:hover 
                                {
                                    .dropdown-items {
                                        cursor: pointer;
                                        visibility: visible;
                                        opacity: 1;
                                        display: flex;
                                        @include media-breakpoint-up(xl) {
                                            transform: translateY(0px);
                                            -webkit-transform: translateY(0px);
                                            -moz-transform: translateY(0px);
                                            -ms-transform: translateY(0px);
                                            -o-transform: translateY(0px);
                                        }
                                        .sub-nav-item:hover {
                                            background-color: $soft-blue;
                                            color: $white !important;
                                            i.fa-chevron-right {
                                                color: $white !important;
                                            }
                                            .list-sub-menu{
                                                visibility: visible;
                                                opacity: 1;
                                                left: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        @include media-breakpoint-up(lg) {
                            border-bottom: 2px solid transparent;
                        }
                    }
                    @include media-breakpoint-down(lg) {
                        &:first-child {
                            padding-top: 30px;
                        }
                    }
                }
            }
            &.scroll-up{
                #navbarSupportedContent{
                    @include media-breakpoint-up(xl) {
                        height: 100%;
                    }
                    ul.navbar-nav li.nav-item {
                        &.nav-mega-menu .dropdown-items {
                            top: 4.7rem;
                        }
                    }
                }
            }
        }
        &.header-transparent .navbar {
            height: 110px;
            background-color: transparent;
            color: $white;
            font-weight: 400;
            @include media-breakpoint-down(lg) {
                height: 70px !important;
            }
            @include media-breakpoint-only(md) {
                height: 60px !important;
            }
            @include media-breakpoint-down(sm) {
                height: 60px !important;
            }
            @include media-breakpoint-down(lg) {
                background-color: #FFF;
            }
            .navbar-brand {
                .navbar-logo-light {
                    display: none;
                }
                .navbar-logo {
                    display: block;
                }
                img {
                    transition: all 0.3s ease-in-out;
                    height: 43px;
                    @include media-breakpoint-down(sm) {
                        height: 33px;
                    }
                    @include media-breakpoint-only(md) {
                        height: 33px;
                    }
                }
            }
            .header-top{
                background: rgba(255, 255, 255, 0.6);
            }
            @include media-breakpoint-up(xl){
                &.scroll-up{
                    background-color: #FFF;
                    color: $hitam;
                    height: 75px;
                    padding-bottom: 0px;
                    .header-top,
                    .current-user {
                        display: none !important;
                    }
                }
                &:not(.scroll-up) {
                    .navbar-brand {
                        .navbar-logo-light {
                            display: block;
                        }
                        .navbar-logo {
                            display: none;
                        }
                    }
                }
            }
        }
        &.header-light .navbar {
            height: 110px;
            background-color: $white;
            color: $hitam;
            font-weight: 400;
            padding-bottom: 0px;
            .current-user span path {
                fill: $hitam !important;
            }
            @include media-breakpoint-down(lg) {
                height: 60px !important;
            }
            .header-top{
                background: #9D9D9D1A;
            }
            .navbar-brand {
                .navbar-logo-light {
                    display: none;
                }
                .navbar-logo {
                    display: block;
                }
            }
            &.scroll-up{
                background-color: #FFF;
                color: $hitam;
                height: 75px;
                padding-bottom: 0px;
                img {
                    transition: all 0.3s ease-in-out;
                    height: 43px;
                    @include media-breakpoint-down(sm) {
                        height: 33px;
                    }
                    @include media-breakpoint-only(md) {
                        height: 33px;
                    }
                }
            }
        }
        .scroll-up{
            .header-top,
            .current-user {
                display: none !important;
            }
        }
    }
}

.navbar-toggler>.close-bar {
    display: inline;
}

.navbar-toggler.collapsed>.close-bar,
.navbar-toggler:not(.collapsed)>.fa-bars {
    display: none;
}

.navbar-toggler {
    margin-top: -3px;
    padding: 0px 0px 0px 10px;
    outline: transparent;
    border: transparent;
    box-shadow: none !important;
    @include media-breakpoint-down(lg){
        &.close-button{
            padding: 20px 30px;
        }
    }
}

.navbar-toggler:focus {
    outline: none;
    border: none;
}

.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
}