.services-item {
    padding-top: 0px !important;
    padding-bottom: 100px !important;
    .row {
        row-gap: 25px;
        .items {
            background: $white;
            border-radius: 20px;
            padding: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            height: 100%;
            @include media-breakpoint-down(lg){
                align-items: flex-start;
                flex-direction: column;
                row-gap: 30px;
            }
            @include media-breakpoint-only(md){
                padding: 30px;
            }
            @include media-breakpoint-down(sm){
                padding: 20px;
                row-gap: 20px;
            }
            .image {
                height: auto;
                width: 160px;
                @include media-breakpoint-only(md){
                    width: 120px;
                }
                @include media-breakpoint-down(sm){
                    width: 90px;
                }
            }
            .section-text {
                padding-left: 30px;
                @include media-breakpoint-down(lg){
                    padding-left: 0px;
                }
                .title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 35px;
                    line-height: 45px;
                    color: $hitam;
                    @include media-breakpoint-down(md){
                        font-size: 26px;
                    }
                }
                .desc {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 26px;
                    color: $hitam;
                }
            }
        }
    }
}