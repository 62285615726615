.ficon {
   width: 16px;
   height: 16px;
   display: inline-block;
   background-size: contain;
   background-position: center;
   background-repeat: no-repeat;
   &.feather--calender {
      background-image: url('../img/icons/fcalendar.svg');
   }
   &.feather--marker {
      background-image: url('../img/icons/fmarker.svg');
   }
}

.glyphicon {
   font-family: 'Font Awesome 5 Free';
   font-weight: 900;
   -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
   display: inline-block;
   font-style: normal;
   font-variant: normal;
   text-rendering: auto;
   line-height: 1;
   &.glyphicon-arrow-right {
      &::before {
         content: '\f054';
      }
   }  
   &.glyphicon-arrow-left {
      &::before {
         content: '\f053';
      }
   }  
}

.logo-date{
   position: absolute;
   right: 2rem;
   top:2rem;
   
}